import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '../../../store/index';
import * as LayoutActions from '../../../store/layout/layout.actions';
import { environment } from '@environments/environment';
import { AppConfigService } from '../../services/app-config.service';

declare var noScroll: any;



@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
})
export class HeaderMobileComponent implements OnInit {

  constructor(
    private store: Store<AppState>,
    private appConfig: AppConfigService
  ) {

  }

  ngOnInit(): void {

  }

  openMenuMobile(ev) {
    ev.preventDefault();

    // Lock body scroll
    noScroll.on();

    this.store.dispatch(new LayoutActions.OpenMenuMobile());
}

  getLogo() {
    return '/assets/images/' + this.appConfig.webConfig.logo + '.svg';
  }
  getLogoName() {
      return this.appConfig.webConfig.logoName;
  }

}
