import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { WrapperComponent } from './wrapper/wrapper.component';
import { HeaderMobileComponent } from '../components/header-mobile/header-mobile.component';
import { MainMenuComponent } from '../components/main-menu/main-menu.component';
import { AsideLeftMenuComponent } from '../components/aside-left-menu/aside-left-menu.component';
import { AdminInfoComponent } from '../components/admin-info/admin-info.component';
import { BreadcrumbComponent } from '../components/breadcrumb/breadcrumb.component';
import { PaginatorComponent } from '../components/paginator/paginator.component';
import { DialogConfirmComponent } from '../components/dialog/dialog-confirm/dialog-confirm.component';
import { DialogErrorComponent } from '../components/dialog/dialog-error/dialog-error.component';


import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DialogInfoComponent } from '../components/dialog/dialog-info/dialog-info.component';
import { AsideSecondaryComponent } from '../components/aside-secondary/aside-secondary.component';
import { PipesModule } from '../pipes/pipes.module';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AsideItemsComponent } from '../components/aside-items/aside-items.component';
import { JoditAngularModule } from 'jodit-angular';
import { ModalResourceComponent } from '../components/modal-resource/modal-resource.component';
import { NewResourceComponent } from '../components/resources/new-resource/new-resource.component';
import { ResourcePdfComponent } from '../components/resources/resource-pdf/resource-pdf.component';
import { CommonResourceComponent } from '../components/resources/common-resource/common-resource.component';
import { ResourceVimeoComponent } from '../components/resources/resource-vimeo/resource-vimeo.component';
import { ResourceYoutubeComponent } from '../components/resources/resource-youtube/resource-youtube.component';
import { ResourceWordComponent } from '../components/resources/resource-word/resource-word.component';
import { ResourceImageComponent } from '../components/resources/resource-image/resource-image.component';
import { ResourceLinkComponent } from '../components/resources/resource-link/resource-link.component';
import { GroupComponent } from '../components/group/group.component';
import { StudentComponent } from '../components/student/student.component';
import { MemberInfoComponent } from '../components/member-info/member-info.component';
import { ComunicationAddComponent } from '../components/comunication-add/comunication-add.component';

import { PdfViewerModule, LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
  ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService } from '@syncfusion/ej2-angular-pdfviewer';
import { ModalViewerComponent } from '../components/modal-viewer/modal-viewer.component';
import { AsideViewerResourcesComponent } from '../components/aside-viewer-resources/aside-viewer-resources.component';
import { AsidePanelViewerResourcesComponent } from '../components/aside-panel-viewer-resources/aside-panel-viewer-resources.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatTabsModule,
    MatSlideToggleModule,

    FormsModule,
    ReactiveFormsModule,
    PipesModule,

    DragDropModule,
    JoditAngularModule,

    PdfViewerModule
  ],
  declarations: [
    WrapperComponent,
    HeaderMobileComponent,
    MainMenuComponent,
    AsideLeftMenuComponent,
    AsideSecondaryComponent,
    AsidePanelViewerResourcesComponent,
    ModalResourceComponent,
    ModalViewerComponent,
    AsideViewerResourcesComponent,
    AsideItemsComponent,
    AdminInfoComponent,

    BreadcrumbComponent,
    PaginatorComponent,
    DialogConfirmComponent,
    DialogErrorComponent,
    DialogInfoComponent,

    NewResourceComponent,
    ResourcePdfComponent,
    ResourceWordComponent,
    ResourceImageComponent,
    ResourceVimeoComponent,
    ResourceYoutubeComponent,
    ResourceLinkComponent,
    CommonResourceComponent,

    ComunicationAddComponent,

    GroupComponent,
    StudentComponent,
    MemberInfoComponent

    
  ],

  exports: [
    BreadcrumbComponent,
    PaginatorComponent,
    DialogConfirmComponent,
    DialogErrorComponent,
    NewResourceComponent,
    ResourcePdfComponent,
    ResourceWordComponent,
    ResourceImageComponent,
    ResourceVimeoComponent,
    ResourceYoutubeComponent,
    ResourceLinkComponent,
    CommonResourceComponent,

    ComunicationAddComponent,

    GroupComponent,
    StudentComponent,
    MemberInfoComponent
  ],

  providers: [
    LinkAnnotationService, BookmarkViewService, MagnificationService,
    ThumbnailViewService, ToolbarService, NavigationService, TextSearchService, TextSelectionService, PrintService
  ],

  entryComponents: [
    DialogConfirmComponent
  ]
})
export class LayoutsModule {
}
