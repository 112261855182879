import { Action } from '@ngrx/store';

// BLOG
export const DO_GET_INFO_FAQS_CATEGORIES = '[Faqs Categories] Get Info Categories';
export class DoGetInfoFaqsCategories implements Action {
    type = DO_GET_INFO_FAQS_CATEGORIES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FAQS_CATEGORIES_OK = '[Faqs Categories] Get Info Categories OK';
export class DoGetInfoFaqsCategoriesOK implements Action {
    type = DO_GET_INFO_FAQS_CATEGORIES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FAQS_CATEGORIES_KO = '[Faqs Categories] Get Info Categories KO';
export class DoGetInfoFaqsCategoriesKO implements Action {
    type = DO_GET_INFO_FAQS_CATEGORIES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Faqs Categories] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoFaqsCategories | DoGetInfoFaqsCategoriesOK | DoGetInfoFaqsCategoriesKO 
    | DoSetSelectedFilters
    ;
