import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/shared/services';
import { RoutesService } from '../services/routes.service';


import { Store } from '@ngrx/store';
import { AppState } from '../../store/index';
import { AuthState } from '../../store/auth/auth.state';
import * as loggedActions from '../../store/auth/auth.actions';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    loggedUser$: Observable<AuthState>;
    isLogged: boolean;
    init: boolean;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private routesService: RoutesService,
        private store: Store<AppState>
    ) {

     }

    canActivate() {
        // console.log('COMPROBANDO ACTIVATE');
        if (this.authenticationService.isLogged()) {
            return true;
        }

        // console.log('NO ESTA LOGUEADO');

        this.router.navigate([this.routesService.getRoute('login')]);
        return false;
    }
}