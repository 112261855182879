import { Action } from '@ngrx/store';
import { Credentials } from '../../shared/models/credentials';
import { User } from '../../shared/models/user';


export const INIT_LOGIN = '[Auth] Login';
export class InitLogin implements Action {
    type = INIT_LOGIN;
    constructor() { }
}

export const APP_INIT = '[Auth] Init';
export class InitApp implements Action {
    type = APP_INIT;
    constructor() { }
}

export const AUTO_LOGIN = '[Auth] Auto Login';
export class AutoLogin implements Action {
    type = AUTO_LOGIN;
    constructor() { }
}

export const AUTO_LOGIN_SUCCESS = '[Auth] Auto Login Success';
export class AutoLoginSuccess implements Action {
    type = AUTO_LOGIN_SUCCESS;
    constructor(public payload: any) { }
}

export const AUTO_LOGIN_FAIL = '[Auth] Auto Login Fail';
export class AutoLoginFail implements Action {
    type = AUTO_LOGIN_FAIL;
    constructor(public payload: any) { }
}

export const DO_LOGIN = '[Auth] Login';
export class DoLogin implements Action {
    type = DO_LOGIN;
    constructor(public payload: Credentials) { }
}

export const DO_LOGIN_SUCCESS = '[Auth] Login Success';
export class DoLoginSuccess implements Action {
    type = DO_LOGIN_SUCCESS;
    constructor(public payload: any) { }
}

export const DO_LOGIN_FAIL = '[Auth] Login Fail';
export class DoLoginFail implements Action {
    type = DO_LOGIN_FAIL;
    constructor(public payload: any) { }
}

export const SET_USER = '[Auth] Set User';
export class SetUser implements Action {
    type = SET_USER;
    constructor() { }
}

export const SET_USER_SUCCESS = '[Auth] Set User Success';
export class SetUserSuccess implements Action {
    type = SET_USER_SUCCESS;
    constructor(public payload: any) { }
}

export const SET_USER_FAIL = '[Auth] Login Fail';
export class SetUserFail implements Action {
    type = SET_USER_FAIL;
    constructor(public payload: any) { }
}

// export const DO_UPDATE_USER = '[Auth] Update user';
// export class DoUpdateUser implements Action {
//     type = DO_UPDATE_USER;
//     constructor(public payload: any) { }
// }

export const DO_LOGOUT = '[Auth] Logout';
export class DoLogout implements Action {
    type = DO_LOGOUT;
    constructor() { }
}

export type All = DoLogin 
    | AutoLogin | AutoLoginSuccess | AutoLoginFail
    | DoLoginSuccess | DoLoginFail
    | DoLogout | SetUser | SetUserSuccess | SetUserFail;
