export class Student {
    id: number;

    name: string;
    surname: string;
    description: string;
    enabled: boolean;

    created_at: string;
}
