import { I18n } from './i18n';

export class Site {
    _id: string;
    name: any;
    code: string;
    created_at: string;
    updated_at: string;
    enabled: boolean;
    i18n: Array<I18n>;
}