import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/shared/services';
import { RoutesService } from '../../shared/services/routes.service';
import { AdminService } from '../../shared/services/admin.service';

@Component({ templateUrl: 'remember.component.html' })

export class RememberComponent implements OnInit {
    rememberForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    emailSend = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private routesService: RoutesService,
        private authenticationService: AuthenticationService,
        private adminService: AdminService
    ) {
        // redirect to home if already logged in
        // if (this.authenticationService.currentUserValue) {
        //     this.router.navigate([this.routesService.getRoute('dashboard')]);
        // }
    }

    ngOnInit() {
        this.rememberForm = this.formBuilder.group({
            user: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.rememberForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.rememberForm.invalid) {
            return;
        }

        this.loading = true;
        this.adminService.remember(this.f.user.value)
            .pipe(first())
            .subscribe(
                data => {
                    console.log('data', data);
                    if (data && data.result === 'OK') {
                        this.emailSend = true;
                    } else {
                        this.submitted = false;
                        this.error = 'No se ha encontrado ese email en nuestro sistema';
                    }
                },
                error => {
                    this.error = error;
                    this.loading = false;
                });
    }
}
