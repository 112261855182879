import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/shared/models';

@Injectable({ providedIn: 'root' })
export class AdminService {
    constructor(private http: HttpClient) { }

    update(name: string, user: string, password_current: string) {
        return this.http.patch<any>(`${environment.apiUrl}/user`, { name, user, password_current })
            .pipe(map(userData => {
                return userData;
            }));
    }

    detail() {
        return this.http.get<User>(`${environment.apiUrl}/user`);
    }

    remember(user: string) {
        return this.http.post<any>(`${environment.apiUrl}/user/remember`, { email: user })
            .pipe(map(userData => {
                return userData;
            }));
    }

    // changePassword(hash: string, data: any) {
    //     return this.http.post<any>(`${environment.apiUrl}/user/password/reset/` + hash, data )
    //         .pipe(map(userData => {
    //             return userData;
    //         }));
    // }
}