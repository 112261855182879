export class ResourceCommonValues {
    all_pages: boolean;
    in_chapter: boolean;
    interval_pages: boolean;

    bookindex_id: number;
    page_start: number;
    page_end: number;
    pages: string;

    private: boolean;
    only_teachers: boolean;


    constructor() {
        this.all_pages = true;
        this.in_chapter = false;
        this.interval_pages = false;
    }
}
