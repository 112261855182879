import { Component, Inject } from '@angular/core';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  content: any;
  type: string;
}


@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
})
export class DialogInfoComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      // console.log('DATA DIALOG', data);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

}