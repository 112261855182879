import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Page } from '../models/page';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class PagesService {
    constructor(
        private request: CustomRequest
    ) { }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1}, filters: {} };
        return this.request.get(`${environment.apiUrl}/page-type`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/page`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/page/` + id + '?boxes=1');
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/page/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/page/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/page/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/page/` + id + `/enabled/` + status, null);
    }

    module(id) {
        return this.request.get(`${environment.apiUrl}/module/` + id, null);
    }

    createModule(data) {
        return this.request.post(`${environment.apiUrl}/module/`, data);
    }

    setModule(data) {
        return this.request.post(`${environment.apiUrl}/module/` + data._id, data);
    }

    changeStatusModule(id, status) {
        return this.request.post(`${environment.apiUrl}/module/` + id + `/enabled/` + status, null);
    }

    getModuleTypes(data) {
        return this.request.get(`${environment.apiUrl}/module-type`, data);
    }

    createBox(data: any) {
        return this.request.post(`${environment.apiUrl}/page-module/`, data);
    }

    setBox(data: any) {
        return this.request.post(`${environment.apiUrl}/page-module/` + data._id, data.box);
    }

    orderBox(data: any) {
        return this.request.post(`${environment.apiUrl}/page-module/order`, data);
    }

    deleteModuleBox(id: string) {
        return this.request.delete(`${environment.apiUrl}/page-module/` + id);
    }

    transformDataFromApi(data: any) {
        const page = new Page();

        page.boxes = this.transformBoxes(data);
        page.modules = data.modules;
        page.sites = data.sites;
        page.urls = data.urls;
        page._id = data._id;
        page.enabled = data.enabled;
        page.seo = data.seo;
        page.title = data.title;
        page.created_at = data.created_at;
        page.updated_at = data.updated_at;
        page.related_id = data.related_id;
        page.related_table = data.related_table;

        page.classes = data.classes;

        // console.log('PAGE data', data);
        // console.log('PAGE FINAL', page);

        return page;

    }

    transformBoxes(data) {
        if (!data.boxes) {
            return null;
        }

        return data.boxes;
    }

    getSeoInformationOnPage(page, locale) {
        return page.seo[locale];
    }

    getSeo(page, lang) {
        if (!page.seo) {
            return null;
        }

        return page.seo[lang];
    }
}