import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ConsoleUtils } from '../../helpers/console-utils';

declare var noScroll: any;

@Component({
    selector: 'app-aside-secondary',
    templateUrl: './aside-secondary.component.html',
})
export class AsideSecondaryComponent implements OnDestroy {
    openAsideSecondary: boolean;

    mode: any;

    title: string;

    hasBtnOnFooter: boolean;
    isSaving: boolean;
    hideActions: boolean;

    labels: any = null;

    error: string = null;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>
    ) {

        this.initSuscriptions();

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.aside.error).subscribe(res => {
            this.error = res;
        }));
        

        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideSecondary).subscribe(res => {
            this.openAsideSecondary = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.title).subscribe(res => {            
            this.title = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.mode).subscribe(res => {
            this.mode = res;

            this.initMode();
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            this.isSaving = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.hideActions).subscribe(res => {
            this.hideActions = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.labels).subscribe(res => {
            this.labels = res;
        }));

    }

    initMode() {
        // ConsoleUtils.log('mode', this.mode);
        switch (this.mode) {
            case 'newResource':
                this.title = 'Nuevo recurso';
                this.hasBtnOnFooter = false;
                break;

            case '1':
            case 1:
                this.title = 'PDF';
                this.hasBtnOnFooter = true;
                break;

            case '3':
            case 3:
                this.title = 'Vídeo Youtube';
                this.hasBtnOnFooter = true;
                break;

            case '4':
            case 4:
                this.title = 'Vídeo Vimeo';
                this.hasBtnOnFooter = true;
                break;

            case '5':
            case 5:
                this.title = 'Documento';
                this.hasBtnOnFooter = true;
                break;

            case '6':
            case 6:
                this.title = 'Imagen';
                this.hasBtnOnFooter = true;
                break;

            case '7':
            case 7:
                this.title = 'Enlace externo';
                this.hasBtnOnFooter = true;
                break;

            case 'editMember':                
                this.hasBtnOnFooter = false;
                break;

            default:
                this.hasBtnOnFooter = true;
                break;
        }
    }

    close() {
        this.store.dispatch(new LayoutActions.CloseAsideSecondary());
        this.store.dispatch(new AsideActions.ResetInfo());
    }

    save() {
        this.store.dispatch(new AsideActions.SetToSave());
    }
}
