import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { objectToFormData } from 'object-to-formdata';
import { DatePipe } from '@angular/common';
import { ImageUtils } from './image-utils';

@Injectable({ providedIn: 'root' })
export class CustomRequest {

    constructor(
        private http: HttpClient
    ) { }

    private getFiltersParams(data: any) {
        if (!data || (!data.filters && !data.paginator)) {
            return null;
        }

        const filters: any = {};
        const params = new HttpParams();

        if (data.filters) {
            if (data.filters.textSearch) {
                filters.search = data.filters.textSearch;
            }

            if (data.filters.status > -1) {
                filters.enabled = data.filters.status;
            }

            if (data.filters.checked > -1) {
                filters.checked = data.filters.checked;
            }

            const datePipe = new DatePipe('en-US');
            if (data.filters.date) {
                filters.published_at = datePipe.transform(data.filters.date, 'yyy-MM-dd');
            }

            if (data.filters.start_at) {
                filters.start_at = datePipe.transform(data.filters.start_at, 'yyy-MM-dd');
            }

            if (data.filters.end_at) {
                filters.end_at = datePipe.transform(data.filters.end_at, 'yyy-MM-dd');
            }

            if (data.filters.category && data.filters.category !== '') {
                filters.category = data.filters.category;
            }

            if (data.filters.orderBy && data.filters.orderBy !== '') {
                filters.orderBy = data.filters.orderBy;
            }

            if (data.filters.type && data.filters.type !== '') {
                filters.type = data.filters.type;
            }

            if (data.filters.province && data.filters.province !== '') {
                filters.province = data.filters.province;
            }

            if (data.filters.group && data.filters.group !== '') {
                filters.group = data.filters.group;
            }

            if (data.filters.collection && data.filters.collection !== '') {
                filters.collections = [data.filters.collection];
            }


            if (data.filters.global > -1) {
                filters.global = [data.filters.global];
            }

            if (data.filters.location && data.filters.location !== '') {
                filters.location = data.filters.location;
            }
        }

        if (data.orderBy) {
            filters.order = data.orderBy;
        }

        if (data.orderByType) {
            filters.order_type = data.orderByType;
        }

        if (data.paginator) {
            filters.page = data.paginator.currentPage;
            filters.limit = data.paginator.perPage;
        }

        return new HttpParams({
            fromObject : filters
        });
    }

    private setFormData() {
        const options = {
            indices: false,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
        };

        const formData = objectToFormData(
            this.post,
            options
        );

        return formData;
    }

    get(path: string, data?: any) {
        const params = this.getFiltersParams(data);
        return this.http.get<any>(path, { params });
    }

    postPayload(payload) {
        // return ImageUtils.binaryToBase64(payload);
        return payload;
    }
    post(path: string, payload: any) {
        return this.http.post<any>(path, this.postPayload(payload));
    }

    delete(path: string) {
        return this.http.delete<any>(path);
    }

    getFile(path: string, data?: any, headers?: any, responseType?: any) {
        const params = this.getFiltersParams(data);
        return this.http.get<any>(path, { params, headers, responseType });
    }
}
