import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import * as LayoutActions from '@app/store/layout/layout.actions';
import { BooksService } from '@app/shared/services/books.service';
import { first } from 'rxjs/operators';
import { ConsoleUtils } from '@app/shared/helpers/console-utils';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { GroupsService } from '@app/shared/services/groups.service';


@Component({
    selector: 'app-new-resource-form',
    templateUrl: './new-resource.component.html',
    providers: []
})
export class NewResourceComponent implements OnDestroy {

    data: any;
    section: string;
    resources: Array<any>;

    itemForm: FormGroup;
    submitted = false;

    resourcesBook: Array<any> = [];    
    resourcesNotInGroup: Array<any> = [];    
    bookIndex: any;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private bookService: BooksService,
        private groupService: GroupsService,
    ) {
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            this.data = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;

            this.initResources();

            this.initResourcesToGroup();
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    initResources() {
        this.initForm();

        this.resources = [
            { name: 'PDF', value: 1 },
            // { name: 'Vídeo', value: 2 },
            { name: 'Vídeo Youtube', value: 3 },
            { name: 'Vídeo Vimeo', value: 4 },
            { name: 'Documento Word', value: 5 },
            { name: 'Imagen', value: 6 },
            { name: 'Enlace', value: 7 }
        ];
    }

    getName(item) {
        return this.getNameByCode(item.value);
    }

    getNameByCode(value) {
        const resource = this.resources.filter(x => x.value === value)[0];
        return resource.name;
    }

    get f() { return this.itemForm.controls; }

    initForm() {
        this.itemForm = this.formBuilder.group({
            resource: ['', Validators.required]
        });
    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    onSubmit() {
        this.submitted = true;
        if (this.itemForm.invalid) {
            return this.cancelSave();
        }

        this.create();
    }

    create() {
        const code = this.f.resource.value;
        let itemType = null;
        let name = null;
        this.resources.forEach(item => {
            if (item.value === code) {
                name = item.name;
                itemType = item;
            }
        });

        const mode = this.f.resource.value;
        const payload = { mode, title: name };
        this.store.dispatch(new AsideActions.ChangeMode(payload));
    }

    initResourcesToGroup() {
        if (this.section !== 'groups' || !this.data.group) {
            this.resourcesBook = [];
            return;
        }

        const dataToSend = {
            group_id: this.data.group.id,
            book_id: this.data.book.id,
            owner_id: this.data.group.user_id
        };

        this.bookService.resources(dataToSend).pipe(
            first()).subscribe(res => {
                this.resourcesBook  = res.resources;
                this.bookIndex  = res.bookIndex;
                this.initResourcesNotInGroup();
                ConsoleUtils.log('resourcesBook', this.resourcesBook);
            },
            error => {
                ConsoleUtils.log('Error en obtencion de recursos', error);
            });
    }

    isEoResource(resource) {
        return !resource.user_id || resource.user_id !== this.data.group.user_id;
    }

    getChapterById(chapterId) {
        if (!this.data.book || !this.data.book.book_model || !this.bookIndex) {
            return;
        }

        let nameChapter = '';
        this.bookIndex.forEach(element => {
            if (element.id === chapterId) {
                nameChapter = element.name;
            }
        });

        return nameChapter;
    }
    
    getIconByType(resource) {
        return ModulesUtils.getIconByType(resource);
    }

    viewResource(resource, book) {
        switch (resource.typeName) {
            case 'Vimeo':
            case 'Youtube':
            case 'PDF':
                resource.book_id = book.id;
                const payload = resource;
                this.store.dispatch(new AsideItemActions.SetModalContent(payload));
                this.store.dispatch(new LayoutActions.OpenModalResource());
                break;

            // case 'PDF':
            //     this.openFile(book.id, resource.id);
            //     // const url = environment.apiUrl + '/resources/' + book.id + '/' + resource.id;
            //     // window.open(url, '_blank');
            //     break;

            case 'Image':
                window.open('http://google.com', '_blank');
                break;
        }

    }

    addResource(resource) {
        if (!this.data.group) {
            return;
        }

        const dataToSend = {
            group_id: this.data.group.id,
            book_id: this.data.book.id,
            resource_id: resource.id
        };
        this.groupService.addResource(dataToSend).pipe(
            first()).subscribe(res => {
                this.resourcesNotInGroup = this.resourcesNotInGroup.filter( element => element.id !== resource.id);

                const dataResult = { refresh: true, closeItem: true };
                this.store.dispatch(new AsideItemActions.SetResult(dataResult));                
            },
            error => {
                ConsoleUtils.log('Error en obtencion de recursos', error);
            });
    }

    hasResourceGroup(resource) {
        const resources = this.data.group && this.data.group.resources ? this.data.group.resources : null;

        if (!this.resources) {
            return false;
        }

        let find = false;
        if (resources && resources.length) {
            resources.forEach(element => {
                if (element.id === resource.id) {
                    find = true;
                    return;
                }
            });
        }
        ConsoleUtils.log('FIND....', find);
        return find;
    }

    initResourcesNotInGroup() {
        const resourcesNotInGroup = [];

        this.resourcesBook.forEach(element => {
            if (!this.hasResourceGroup(element)) {
                resourcesNotInGroup.push(element);
            }
        });

        this.resourcesNotInGroup = resourcesNotInGroup;

        this.orderResourcesNotInGroup();
    }

    orderResourcesNotInGroup() {
        this.resourcesNotInGroup.sort((a, b) => {
            if (a.page < b.page) return -1;
            else if (a.page > b.page) return 1;
            else return 0;
          });
    }
}
