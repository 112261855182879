import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Book } from '../models/book';
import { CustomRequest } from '../helpers/custom-request';
import { AppConfigService } from './app-config.service';
import { ConsoleUtils } from '../helpers/console-utils';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '.';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BooksService {

    private viewerConfig = new BehaviorSubject({});

    constructor(
        private request: CustomRequest,
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
    }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1 }, filters: {} };
        return this.request.get(`${environment.apiUrl}/page-type`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/books`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/books/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/books/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/books/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/books/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/books/` + id + `/enabled/` + status, null);
    }

    resources(data) {
        return this.request.post(`${environment.apiUrl}/books/` + data.book_id + `/resources`, data);
    }

    view(id) {
        const headers = {
            "Content-Type": "application/json",
            Accept: "application/pdf"
        }
        const responseType = { responseType: 'blob' };
        return this.request.getFile(`${environment.apiUrl}/books/` + id + `/view`, null, headers, responseType);
    }

    open(id) {
        const token = this.authenticationService.authToken;

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
                observe: 'response'
            })
        };
        return this.http.get<any>(`${environment.apiUrl}/books/` + id + `/view`, httpOptions);
        //   return this.http.get<any>(`${environment.apiUrl}/resources/` + id + `/` + 26, httpOptions);
    }

    openChapter(id, idChapter) {
        const token = this.authenticationService.authToken;

        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${token}`,
                observe: 'response'
            })
        };
        return this.http.get<any>(`${environment.apiUrl}/books/` + id + `/viewChapter/` + idChapter, httpOptions);
        //   return this.http.get<any>(`${environment.apiUrl}/resources/` + id + `/` + 26, httpOptions);
    }

    saveViewerConfig(data: any) {
        return this.request.post(`${environment.apiUrl}/books/` + data.book_id + `/saveChapterConfig/` + data.chapter_id, data);
    }


    transformDataFromApi(data: any) {
        const book = new Book();

        // ConsoleUtils.log('data in book', data);

        // book.id_suscription = data.id;
        book.expires_at = data.expires_at ? data.expires_at : null;

        book.id = data.book.id;
        book.name = data.book.book_model.name;
        book.book_model = data.book.book_model;
        book.description = data.book.book_model.description;
        book.indexTxt = data.book.book_model.indexbook;
        book.pages = data.book.pages;
        book.category = data.book.category;
        book.image = (environment.baseResources + data.book.book_model.image);

        book.resources = data.resources ? data.resources : null;
        book.groups = data.groups ? data.groups : null;

        book.currentGroup = null;
        book.chaptersUser = data.chaptersUser ? data.chaptersUser : null;;
        return book;

    }

    transformListFromApi(list: Array<any>) {
        const data = [];

        let book;

        list.forEach(element => {
            book = this.transformDataFromApi(element);
            book.id_suscription = element.id;
            book.expires_at = element.expires_at;

            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    testDetail() {
        return {
            id: 1,
            name: 'AutoCAD Básico en Interpretación Gráfica 2020',
            category: {
                id: 23,
                name: 'Libro digital'
            },
            image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
            description: '<p>Hoy en día es inconcebible pensar en una empresa que no tenga un departamento o sección de mantenimiento, ya que las ventajas que ofrece son enormes.</p> <p>Este manual es apropiado para estudiantes de cualquier nivel, así como para profesionales del sector.</p> <p>Intenta ser, ante todo, práctico, explicando los conceptos de forma clara y sencilla, auxiliándose de un sinfín de ilustraciones.</p>',
            pages: 362,
            indexTxt: '<p>A continuación mostramos el índice de contenidos del libro:</p> <ol> <li>Mantenimiento</li> <li>Mantenimiento Mecánico</li> <li>Mecanismos</li> <li>Elementos de arrastre y transmisión I</li> <li>Ensamblado de piezas</li> <li>Soldadura</li> <li>Guías de deslizamiento</li> <li>Cojinetes</li> <li>Rodamientos</li> <li>Elementos de arrastre y transmisión II</li> <li>Engrase y lubricación de máquinas</li> <li>Obturación de piezas y juntas</li> <li>Metrología dimensional</li> <li>Verificación de elementos e instalación</li> </ol>',
            resources: this.resourcesList(),
            enabled: 1
        };
    }

    testList() {
        return [
            {
                id: 49,
                name: 'AutoCAD Básico en Interpretación Gráfica 2020',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                category: {
                    id: 23,
                    name: 'Libro digital'
                }
            },
            {
                id: 48,
                name: 'Prevención de Riesgos Laborales',
                image: '/storage/resources/bookmodel/image/55767fbc-procesos-de-prl.jpg',
                category: {
                    id: 23,
                    name: 'Libro digital'
                }
            },
            {
                id: 42,
                name: 'Elementos de Máquinas',
                image: '/storage/resources/bookmodel/image/047f7bd0-elementos-maquinas_boceto2.jpg',
                category: {
                    id: 23,
                    name: 'Libro digital'
                }
            },
            {
                id: 39,
                name: 'Prácticas de Metrología y Ensayos',
                image: '/storage/resources/bookmodel/image/e1f83a2f-pr-cticas-laboratorio.jpg',
                category: {
                    id: 23,
                    name: 'Libro digital'
                }
            }
        ];
    }

    resourcesList() {
        return [
            {
                id: 49,
                type: 'Vimeo',
                id_social: '241329822',
                name: 'Tornillo sinfín - Rueda',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: 'Mecanismos',
                pages: null,
                only_teachers: false,
                owner: 'Ediciones EO'
            },
            {
                id: 49,
                type: 'PDF',
                id_social: null,
                name: 'Examen 1 Mantenimiento',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: 'Mecanismos',
                pages: null,
                only_teachers: true,
                owner: 'Ediciones EO'
            },
            {
                id: 49,
                type: 'PDF',
                id_social: null,
                name: 'Examen 2 Mantenimiento',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: 'Mantenimiento mecánico',
                pages: null,
                only_teachers: true,
                owner: 'Ediciones EO'
            },
            {
                id: 49,
                type: 'PDF',
                id_social: '241329822',
                name: 'Resolución Ejercicios 1 Mantenimiento',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: false,
                pages: {
                    pages: '120,121,220'
                },
                only_teachers: true,
                owner: 'Jorge Canteli'
            },
            {
                id: 49,
                type: 'Vimeo',
                id_social: '241574338',
                name: 'Sistemas de Roscas',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: 'Mecanismos',
                pages: null,
                only_teachers: false,
                owner: 'Ediciones EO'
            },
            {
                id: 49,
                type: 'Vimeo',
                id_social: '241329822',
                name: 'Tornillo sinfín - Rueda',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: false,
                pages: {
                    start: 120,
                    end: 122
                },
                only_teachers: false,
                owner: 'Jorge Canteli'
            },
            {
                id: 49,
                type: 'Vimeo',
                id_social: '241329822',
                name: 'Realización de una junta',
                image: '/storage/resources/bookmodel/image/588c564b-portada-autocad.jpg',
                all_pages: false,
                chapter: 'Mecanismos',
                pages: null,
                only_teachers: false,
                owner: 'Ediciones EO'
            }
        ];
    }

    async getViewerConfig(viewer: any): Promise<any> {
        return new Promise((resolve, fail) => {
            viewer.exportAnnotationsAsObject().then(function (value) {
                return resolve(value);
            });

        });
    }

    getCurrentViewerConfig(viewer): Observable<object[]> {
        return new Observable(observer => {
            this.getViewerConfig(viewer).then((value: any) => {
                return observer.next(value);
            });
        })
    }

    maintenanceBookPdf(service) {
        return this.request.get(`${service}`);
    }
}
