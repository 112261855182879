import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from './shared/services/authentication.service';
import { User } from './shared/models/user';
import { RoutesService } from './shared/services/routes.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  currentUser: User;

  title = 'cms';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private routesService: RoutesService
  ) {
    // this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

  }

  logout() {
    // this.authenticationService.logout();
    // this.router.navigate([this.routesService.getRoute('login')]);
  }
}
