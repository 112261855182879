import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';

import { Store } from '@ngrx/store';
import { Observable, fromEventPattern } from 'rxjs';
import { AppState } from '../../../store/index';
import * as LayoutActions from '../../../store/layout/layout.actions';
import * as loggedActions from '../../../store/auth/auth.actions';
import { environment } from '../../../../environments/environment';
import { AppConfigService } from '../../services/app-config.service';

declare var noScroll: any;

@Component({
    selector: 'app-aside-viewer-resources',
    templateUrl: './aside-viewer-resources.component.html',
})
export class AsideViewerResourcesComponent implements OnInit {

    currentSection$: Observable<string>;
    isCollapseAside$: Observable<boolean>;
    isMobileMenuOpen$: Observable<boolean>;
    openAsideConfig$: Observable<boolean>;

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private routesService: RoutesService,
        private appConfig: AppConfigService
    ) {
        this.currentSection$ = this.store.select(state => state.layout.menuSection);
        this.isCollapseAside$ = this.store.select(state => state.layout.isCollapseAside);
        this.isMobileMenuOpen$ = this.store.select(state => state.layout.isMobileMenuOpen);
        this.openAsideConfig$ = this.store.select(state => state.layout.openAsideConfig);
    }

    ngOnInit(): void {

    }

    changeSection(ev, section) {
        ev.preventDefault();

        // Lock body scroll
        noScroll.off();

        // this.eventsService.broadcast(this.eventsService.CURRENT_MENU_SECTION, section);
        this.store.dispatch(new LayoutActions.SetMainSection(section));
        this.router.navigate([this.routesService.getRoute(section)], {});
    }


    clickAsideCollapsed(ev) {
        ev.preventDefault();

        this.store.dispatch(new LayoutActions.ToggleCollapsed());
    }

    openMenuMobile(ev) {
        ev.preventDefault();
        // Lock body scroll
        noScroll.on();

        this.store.dispatch(new LayoutActions.OpenMenuMobile());
    }

    displayAsideLeftPanel(ev, section: string) {
        ev.preventDefault();

        this.store.dispatch(new LayoutActions.OpenAsideConfig(section));
    }

    logout(ev) {
        ev.preventDefault();

        this.store.dispatch(
            new loggedActions.DoLogout()
        );
    }
    


}
