import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/helpers/auth.guard';

import { WrapperComponent } from './shared/layouts/wrapper/wrapper.component';
import { LoginComponent } from './cms-access//login/login.component';
import { RememberComponent } from './cms-access/remember';
import { NotFoundComponent } from './not-found/not-found.component';
import { AndresComponent } from './cms/pages/andres/andres.component';
import { TestComponent } from './cms/pages/test';
import { PasswordComponent } from './cms-access/password';



const routes: Routes = [
  {
    path: 'home',
    redirectTo: 'login'
  },

  {
    path: 'login',
    component: LoginComponent
  },

  {
    path: 'recordar',
    component: RememberComponent
  },

  {
    path: 'usuario/password/:hash',
    component: PasswordComponent
  },

  {
    path: 'andres',
    component: AndresComponent
  },

  {
    path: 'test',
    component: TestComponent
  },

  {
    path: '',
    component: WrapperComponent,
    loadChildren: () => import('./cms/cms.module').then(m => m.CmsModule),
    canActivate: [AuthGuard]
  },

  {
    path: '404',
    component: NotFoundComponent
  },

  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


