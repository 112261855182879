import { Action } from '@ngrx/store';


export const SET_INFO = '[Viewer] Set Info';
export class SetInfo implements Action {
    type = SET_INFO;
    constructor(public payload: any) { }
}

export const SET_BOOK = '[Viewer] Set Book';
export class SetBook implements Action {
    type = SET_BOOK;
    constructor(public payload: any) { }
}

export const SET_CHAPTER = '[Viewer] Set Chapter';
export class SetChapter implements Action {
    type = SET_CHAPTER;
    constructor(public payload: any) { }
}

export const SET_PANEL_VIEW = '[Viewer] Set Panel View';
export class SetPanelView implements Action {
    type = SET_PANEL_VIEW;
    constructor(public payload: any) { }
}

export const SET_GLOBAL_PAGE = '[Viewer] Set Global page';
export class SetGlobalPage implements Action {
    type = SET_GLOBAL_PAGE;
    constructor(public payload: number) { }
}

export const SET_TOTALS = '[Viewer] Set Totals';
export class SetTotals implements Action {
    type = SET_TOTALS;
    constructor(public payload: any) { }
}

export const SET_IS_GROUP = '[Viewer] Set IsGroup';
export class SetIsGroup implements Action {
    type = SET_IS_GROUP;
    constructor(public payload: any) { }
}

export const RESET_INFO = '[Viewer] Reset Info';
export class ResetInfo implements Action {
    type = RESET_INFO;
    constructor() { }
}

export type All = SetInfo | ResetInfo | SetBook | SetPanelView | 
    SetChapter | SetGlobalPage | SetTotals | SetIsGroup;
