import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import * as ViewerActions from '@app/store/viewer/viewer.actions';
import { EventsService } from '@app/shared/services/events.service';
import { ResourcesService } from '@app/shared/services/resources.service';
import { first } from 'rxjs/operators';
import { User } from '@app/shared/models';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare var noScroll: any;

@Component({
    selector: 'app-aside-panel-viewer-resources',
    templateUrl: './aside-panel-viewer-resources.component.html',
})
export class AsidePanelViewerResourcesComponent implements OnDestroy {
    openViewerPanelResources: boolean;

    panelView: any;

    title: string;

    book: any;
    chapters: any;
    chapter: any;
    resources: any = [];
    videos: any = [];
    images: any = [];
    documents: any = [];
    links: any = [];
    currentPage: number;

    imagesFiles: any = [];

    filterChapter = true;

    currentUser: User;



    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private eventsService: EventsService,
        private resourcesService: ResourcesService
    ) {

        this.initSuscriptions();

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openViewerPanelResources).subscribe(res => {
            this.openViewerPanelResources = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.currentUser = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.book).subscribe(res => {            

            this.book = res;

            if (this.book) {
                this.chapters = this.book.book_model.bookindex;
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.chapter).subscribe(res => {            
            this.chapter = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.resources).subscribe(res => {                        
            this.resources = res && res.length ? res : [];

            this.filterResources();
            this.calculateTotals();
        }));
        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.currentPage).subscribe(res => {            
            this.currentPage = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.viewer.panelView).subscribe(res => {            
            this.panelView = res;
            this.title = this.setTitle();
        }));

       

    }


    setTitle() {
        switch(this.panelView) {
            case 'chapters': return 'Temas';
            case 'resourcesAll': return 'Material complementario';
            case 'videos': return 'Videos';
            case 'images': return 'Imágenes';
            case 'documents': return 'Documentos';
            case 'links': return 'Enlaces';

            default: return '';
        }
    }

    
    close() {
        this.store.dispatch(new LayoutActions.ClosePanelViewerResources());
    }

    getImagePath(item) {
        return '/storage/resources/'+ item.image;
    }

    isCurrentChapter(itemChapter) {
        return this.chapter && itemChapter.id == this.chapter.id;
    }

    changeChapter(itemChapter) {
        if (this.chapter.id != itemChapter.id) {
            this.eventsService.broadcast(this.eventsService.VIEWER_CHANGE_CHAPTER, itemChapter);
        }
    }

    hasFooter() {
        return this.panelView != 'chapters';
    }

    getItemsToList() {
        let data = [];
        switch(this.panelView) {
            case 'chapters': data = this.chapters;
            break;

            case 'resourcesAll': data = this.resources;
            break;

            case 'videos': data = this.videos;
            break;

            case 'images': data = this.images;
            break;

            case 'documents': data = this.documents;
            break;

            case 'links': data = this.links;
            break;

            default: data = [];
            break;
        }

        if (this.filterChapter) {
            return data;
        }

        return this.filterDataByPage(data);
    }

    filterDataByPage(data) {
        let res = [];

        data.forEach(element => {
            if (this.isVisibleInListing(element)) {
                res.push(element);
            }
        });

        return res;
    }

    viewResource(resource) {
        switch (resource.typeName) {
            case 'Vimeo':
            case 'Youtube':
            case 'PDF':
            case 'Imagen':
            case 'Documento':
                resource.book_id = this.book.id;
                const payload = resource;
                this.store.dispatch(new LayoutActions.OpenModalResource());
                this.store.dispatch(new AsideItemActions.SetModalContent(payload));                
                break;

            case 'URL':
                window.open(resource.url, '_blank');
                break;
        }

    }

    filterResources() {
        this.resetFilters();
        if (!this.resources.length) {            
            return;
        }

        this.resources.forEach(element => {            
        
            switch (element.typeName) {
                case 'Vimeo':
                case 'Youtube':
                    this.videos.push(element);
                    break;

                case 'Documento':
                case 'PDF':
                    this.documents.push(element);
                    break;

                case 'Imagen':
                    this.images.push(element);
                    this.getFile(element, this.images.length - 1);
                    break;

                case 'URL':
                    this.links.push(element);
                    break;
            }
        });
    }

    resetFilters() {
        this.videos = [];
        this.documents = [];
        this.images = [];
        this.links = [];
    }

    getFile(resource, index) {
        this.resourcesService.open(this.book.id, resource.id).pipe(
            first()).subscribe(data => {
                this.images[index].fileData = data.file;
            });
    }

    getImage(item) {
        // let image = '/assets/images/demo/product.png';
        let image = '';
        this.images.forEach(element => {            
        
            if (element.id == item.id && element.fileData) {
                image = element.fileData;
                return;
            }
        });

        return  image;
    }

    hasFigureLayerResource(resource) {
        switch (resource.typeName) {
            case 'URL':
            case 'Documento':
            case 'PDF':
                // return '<i class="fas fa-file-pdf"></i>';
                return false;

            default:
                return true;
        }
    }

    getClassNameResource(resource) {
        return resource.typeName.toLowerCase();
    }

    doFilterResources(ev) {
        this.calculateTotals();
    }

    calculateTotals() {
        let total = 0;
        let totalVideos = 0;
        let totalDocuments = 0;
        let totalImages = 0;
        let totalLinks = 0;

        if (this.filterChapter) {
            total = this.resources.length;
            totalVideos = this.videos.length;
            totalDocuments = this.documents.length;
            totalImages = this.images.length;
            totalLinks = this.links.length;
        } else {
            this.resources.forEach(element => {       
                if (!this.isVisibleInListing(element)) {
                    return;
                }     

                total++;
                switch (element.typeName) {
                    case 'Vimeo':
                    case 'Youtube':
                        totalVideos++;
                        break;
    
                    case 'Documento':
                    case 'PDF':
                        totalDocuments++;
                        break;
    
                    case 'Imagen':
                        totalImages++;
                        break;
    
                    case 'URL':
                        totalLinks++;
                        break;
                }
            });
        }
        
        const payload = { 
            resources: total, 
            videos: totalVideos, 
            documents: totalDocuments, 
            images: totalImages, 
            links: totalLinks, 
        };
        this.store.dispatch(new ViewerActions.SetTotals(payload));        
    }

    isVisibleInListing(resource) {
        if (this.filterChapter) {
            return true;
        }

        return (resource.pages.length && 
                resource.pages.indexOf(this.currentPage) >= 0);
    }

    isVideoResource(resource) {
        return resource && (resource.typeName == 'Vimeo' || resource.typeName == 'Youtube');
    }
    
}
