import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';

import { Store } from '@ngrx/store';
import { Observable, fromEventPattern, Subscription } from 'rxjs';
import { AppState } from '../../../store/index';
import * as LayoutActions from '../../../store/layout/layout.actions';
import * as loggedActions from '../../../store/auth/auth.actions';
import { environment } from '../../../../environments/environment';
import { AppConfigService } from '../../services/app-config.service';
import { User } from '@app/shared/models';
import { UserService } from '@app/shared/services';

declare var noScroll: any;

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
})
export class MainMenuComponent implements OnInit {

    currentSection$: Observable<string>;
    isCollapseAside$: Observable<boolean>;
    isMobileMenuOpen$: Observable<boolean>;
    openAsideConfig$: Observable<boolean>;

    currentUser: User;

    hasGroups: boolean = false;

    private suscriptionsToManage$: Subscription[] = [];


    constructor(
        private store: Store<AppState>,
        private router: Router,
        private routesService: RoutesService,
        private userServices: UserService,
        private appConfig: AppConfigService
    ) {
        this.currentSection$ = this.store.select(state => state.layout.menuSection);
        this.isCollapseAside$ = this.store.select(state => state.layout.isCollapseAside);
        this.isMobileMenuOpen$ = this.store.select(state => state.layout.isMobileMenuOpen);
        this.openAsideConfig$ = this.store.select(state => state.layout.openAsideConfig);
    }

    ngOnInit(): void {
        this.initSuscriptions();
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.currentUser = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.groups.items).subscribe(res => {
            this.hasGroups = (res && res.length) ? true : false;
        }));
    }

    changeSection(ev, section) {
        ev.preventDefault();

        // Lock body scroll
        noScroll.off();

        // this.eventsService.broadcast(this.eventsService.CURRENT_MENU_SECTION, section);
        this.store.dispatch(new LayoutActions.SetMainSection(section));
        this.router.navigate([this.routesService.getRoute(section)], {});
    }


    clickAsideCollapsed(ev) {
        ev.preventDefault();

        this.store.dispatch(new LayoutActions.ToggleCollapsed());
    }

    openMenuMobile(ev) {
        ev.preventDefault();
        // Lock body scroll
        noScroll.on();

        this.store.dispatch(new LayoutActions.OpenMenuMobile());
    }

    displayAsideLeftPanel(ev, section: string) {
        ev.preventDefault();

        this.store.dispatch(new LayoutActions.OpenAsideConfig(section));
    }

    logout(ev) {
        ev.preventDefault();

        this.store.dispatch(
            new loggedActions.DoLogout()
        );
    }

    isTeacher() {
        return this.userServices.isTeacher(this.currentUser);
    }


}
