import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as faqsActions from './faqs.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { FaqsService } from '../../shared/services/faqs.service';
import { DO_GET_INFO_FILTERS, DO_GET_INFO_FAQS } from './faqs.actions';





@Injectable()
export class FaqsEffects {

    constructor(
        private faqsService: FaqsService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoFaqs$: Observable<Action> = this.actions$.pipe(
        ofType(faqsActions.DO_GET_INFO_FAQS),
        switchMap((action: faqsActions.DoGetInfoFaqs) => {
            return this.faqsService.get(action).pipe(
                map( response => {
                    response.list = this.faqsService.transformListFromApi(response.list);
                    return new faqsActions.DoGetInfoFaqsOK(response);
                }),
                catchError(error => of(new faqsActions.DoGetInfoFaqsKO(error)))
            );
        }));

    @Effect()
    DoGetInfoFaqsFilters$: Observable<Action> = this.actions$.pipe(
        ofType(faqsActions.DO_GET_INFO_FILTERS),
        switchMap((action: faqsActions.DoGetInfoFaqsFilters) => {
            return this.faqsService.getFilters().pipe(
                map( response => new faqsActions.DoGetInfoFaqsFiltersOK(response)),
                catchError(error => of(new faqsActions.DoGetInfoFaqsFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(faqsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: faqsActions.DoSetSelectedFilters) => {
            return this.faqsService.get(action).pipe(
                map( response => new faqsActions.DoGetInfoFaqsOK(response)),
                catchError(error => of(new faqsActions.DoGetInfoFaqsKO(error)))
            );
        }));
}
