export class Credentials {
    user: string;
    password: string;

    constructor(obj: Object) {
        Object.assign(this, obj);
    }
}

export class CredentialsHelper {

    static create(): Credentials {
        return {
            user: '',
            password: ''
        };
    }

    static newFromJSON(json: string): Credentials {
        let credentials: Credentials = null;
        try {
            credentials = CredentialsHelper.fromObject(JSON.parse(json));
        } catch (e) {
            console.error('Invalid credentials JSON.', e);
        }
        return credentials;
    }

    static fromObject(obj: Object = {}): Credentials {
        const credentials = CredentialsHelper.create();
        return Object.assign(credentials, obj);
    }
}
