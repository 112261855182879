import { Action } from '@ngrx/store';

// FAQS
export const DO_GET_INFO_FAQS = '[Faqs] Get Info Faqs';
export class DoGetInfoFaqs implements Action {
    type = DO_GET_INFO_FAQS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FAQS_OK = '[Faqs] Get Info Faqs OK';
export class DoGetInfoFaqsOK implements Action {
    type = DO_GET_INFO_FAQS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FAQS_KO = '[Faqs] Get Info Faqs KO';
export class DoGetInfoFaqsKO implements Action {
    type = DO_GET_INFO_FAQS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Faqs] Get Info Filters';
export class DoGetInfoFaqsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Faqs] Get Info Filters OK';
export class DoGetInfoFaqsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Faqs] Get Info Filters KO';
export class DoGetInfoFaqsFiltersKO implements Action {
    type = DO_GET_INFO_FAQS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Faqs] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoFaqs | DoGetInfoFaqsOK | DoGetInfoFaqsKO |
    DoGetInfoFaqsFilters | DoGetInfoFaqsFiltersOK | DoGetInfoFaqsFiltersKO 
    | DoSetSelectedFilters
    ;
