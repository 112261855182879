import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { FilesManager } from '@app/shared/helpers/files-manager';
import { AppConfigService } from '@app/shared/services/app-config.service';
import { User } from '@app/shared/models';
import { ConsoleUtils } from '@app/shared/helpers/console-utils';
import { Group } from '../../models/group';
import { GroupsService } from '../../services/groups.service';
import { UserService } from '../../services/user.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogErrorComponent } from '@app/shared/components/dialog/dialog-error/dialog-error.component';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import * as LayoutActions from '@app/store/layout/layout.actions';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-member-info',
    templateUrl: './member-info.component.html',
    providers: []
})
export class MemberInfoComponent implements OnDestroy {
    myConfig: any;

    editingItem: boolean;

    data: any;
    group: any;
    member: any;
    emails: Array<any> = [];
    dataLoaded = false;

    section: string;

    itemForm: FormGroup = null;
    submitted = false;
    error = '';
    errorCheck = '';

    dataValidate = null;

    userId: number;

    booksSuscriptions: Array<any>;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private appConfig: AppConfigService,
        private groupService: GroupsService,
        private userService: UserService,
        public dialog: MatDialog
    ) {
        this.myConfig = this.appConfig.webConfig;

        this.data = null;

        this.initGlobal();
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }


    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.userId = res.id;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            if (res) {
                this.data = res;

                ConsoleUtils.log('this.data', this.data);
                this.dataLoaded = true;
                if (this.data.group) {
                    this.group = this.data.group;
                }
                if (this.data.member) {
                    this.initMember(this.data.member);
                }
                ConsoleUtils.log('member', this.member);
            } else {
                ConsoleUtils.log('siempre deberia venir', null);
            }
        }));

        // this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
        //   if (res) {
        //     this.onSubmit();
        //   }
        // }));    

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.result).subscribe(res => {

            if (res && res.refresh) {
                this.getDetails();

                if (res.closeItem) {
                    ConsoleUtils.log('cerrando aside items', res);
                    this.store.dispatch(new LayoutActions.CloseAsideItems());
                    this.store.dispatch(new AsideItemActions.ResetInfo());
                }
            }
        }));
    }

    initMember(dataMember) {
        this.member = this.data.member;

        this.getDetails();
    }

    getDetails() {
        this.groupService.detailMember(this.group.id, this.member.id).pipe(
            first()).subscribe(data => {
                this.emails = data.emails;
                // ConsoleUtils.log('emails', this.emails);
                // ConsoleUtils.log('INFO', data);
            },
                error => {
                    this.showError(error);
                }
            );
    }

    get f() { return this.itemForm.controls; }


    reset() {
        this.submitted = false;
        this.itemForm = null;
    }

    initGlobal() {
        this.initForm();
    }

    initForm() {
        this.itemForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]

        });

        this.itemForm.valueChanges.subscribe(val => {
            this.error = null;
        });
    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    // getErrorMessage() {
    //   let error = '';

    //   if (this.itemForm.invalid) {
    //     error += 'Revisa los campos oblitatorios';
    //   }

    //   return error;
    // }

    // onSubmit() {
    //   this.submitted = true;

    //   const payload = { error: null };
    //   this.store.dispatch(new AsideActions.SetError(payload));

    //   if (this.itemForm.invalid) {
    //     ConsoleUtils.log('NO SUBMIT', null);
    //     ConsoleUtils.log('itemForm', this.itemForm);

    //     payload.error = this.getErrorMessage();
    //     this.store.dispatch(new AsideActions.SetError(payload));
    //     return this.cancelSave();
    //   }

    //   ConsoleUtils.log('salvando...', null);
    //   this.save();
    // }


    // save() {

    // }

    showError(error) {
        const dialogRef = this.dialog.open(DialogErrorComponent, {
            // width: '300px',
            panelClass: 'modal-custom',
            data: { error }
        });

        dialogRef.afterClosed().subscribe(result => {

        });
    }

    viewEmail(emailData) {
        const payload = {
            typeName: 'Email',
            name: emailData.title,
            urlView: environment.apiUrl + `/member/` + emailData.user_id + `/view/` + emailData.id + `/g/` + this.group.id
        };

        this.store.dispatch(new LayoutActions.OpenModalResource());
        this.store.dispatch(new AsideItemActions.SetModalContent(payload));
        
    }

    addEmail() {

        this.store.dispatch(new AsideItemActions.SetLabels({
            title: 'Enviando comunicación a ' + this.member.name,
            save: ' Envíar',
            cancel: ' Cancelar',
        }));

        // this.store.dispatch(new AsideActions.ChangeHide(true));
        const payload = { mode: 'newComunication', title: 'Enviando comunicación a ' + this.member.name, data: this.data, section: 'groups' };
        this.store.dispatch(new AsideItemActions.SetInfo(payload));
        this.store.dispatch(new LayoutActions.OpenAsideItems());
    }

    updateEmails() {

    }

    getFullName(member) {
        return member.name + ' ' + member.surname;
    }

}
