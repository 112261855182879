import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect } from '@ngrx/effects';


import * as layoutactions from '../layout/layout.actions';


// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/switchMap';
// import { UserService } from '../../shared/services/api/user.service';


@Injectable()
export class LayoutEffects {

    constructor(
        // private userService: UserService,
        private actions$: Actions
    ) { }

    

}
