export class FaqCategory {
    _id: string;
    name: any;
    code: string;
    image: string;
    image_name: string;
    order: number;
    created_at: string;
    updated_at: string;
    enabled: boolean;
    sites: Array<string>;
    faqs: Array<any>;
}