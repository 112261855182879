import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {


    constructor(
    ) {
    }


    goTopScroll(idElement: string) {
        const el = document.getElementById(idElement);
        if (el) {
            el.scrollTop = 0;
        }
    }
}
