import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';


@Injectable({
    providedIn: 'root'
  })
export class AppConfigService {

    webConfig: any;

    constructor(
        private http: HttpClient
    ) { }

    load() {
        return new Promise((resolve, reject) => {
            this.getData().subscribe((d: any) => {
                Object.assign(environment, d);
                this.webConfig = d;
            });
            resolve();
        });
    }

    getData(): any {
        let res = null;
        try {
            // res = this.http.get(`${environment.apiUrl}/storage/configurations/modules-config.json`);
            res = this.http.get(`assets/config/${environment.webCode}/config.json`);
        } catch (e) {
            console.log("ERROR EN GETDATA", e);
        }
        return res;
    }
}
