import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import * as AuthActions from '@app/store/auth/auth.actions';
import { AuthenticationService } from '@app/shared/services';
import { AppState } from '../../store/index';
import { Store } from '@ngrx/store';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private store: Store<AppState>,
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {            
            if (err.status === 401) {
                // console.log('err', err);
                // auto logout if 401 response returned from api
                this.store.dispatch(new AuthActions.DoLogout());
            }

            const error = err.error.message || err.statusText;
            return throwError(error);
        }));
    }
}
