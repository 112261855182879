export class Page {
    _id: string;
    modules: any;
    boxes: any;
    enabled: boolean;
    deleteable: boolean;
    sites: any;
    urls: any;
    seo: any;
    title: any;

    classes: any;

    created_at: string;
    updated_at: string;

    related_id: string;
    related_table: string;
}