import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './auth.actions';
import { AuthState } from './auth.state';
import { User } from '@app/shared/models';
import { SET_USER_FAIL } from './auth.actions';


const defaultState: AuthState = {
    user: null,
    accessToken: null,
    error: null,
    errorType: null,
    logged: false,
    loading: false,
    init: false
};

export type Action = fromActions.All;

export function AuthReducer(state = defaultState, action: any) {
    // console.log(state, action);
    switch (action.type) {

        case fromActions.INIT_LOGIN:
            return {
                ...state,
                loading: true,
                init: true
            };

        case fromActions.APP_INIT:
            return {
                ...state,
                init: true
            };

        case fromActions.AUTO_LOGIN:
                return {
                ...state,
                accessToken: action.payload,
                loading: true
            };

        case fromActions.AUTO_LOGIN_SUCCESS:
            return {
                ...state,
                logged: true,
                user: action.payload.user,
                error: null,
                errorType: null,
                loading: false,
                init: true
            };

        case fromActions.AUTO_LOGIN_FAIL:
            return {
                ...state,
                logged: false,
                accessToken: null,
                user: null,
                // error: action.payload,
                errorType: 'login',
                loading: false,
                init: true
            };

        case fromActions.DO_LOGIN:
            return {
                ...state,
                loading: true
            };

        case fromActions.DO_LOGIN_SUCCESS:
            return {
                ...state,
                logged: true,
                accessToken: action.payload,
                error: null,
                errorType: null,
                loading: false,
                init: true
            };

        case fromActions.DO_LOGIN_FAIL:
            return {
                ...state,
                logged: false,
                accessToken: null,
                error: action.payload,
                errorType: 'login',
                loading: false,
                init: true
            };

        case fromActions.DO_LOGOUT:
            return {
                ...state,
                logged: false,
                accessToken: null,
                errorType: null,
                loading: false,
                init: true
            };

        case fromActions.SET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user
            };

        case fromActions.SET_USER_FAIL:
            return {
                ...state,
                user: null
            };


        default:
            return state;
    }
}
