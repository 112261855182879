import { Injectable } from '@angular/core';
import { Resource } from '../models/resource';
import { ResourceCommonValues } from '../models/resourceCommonValues';
import { ConsoleUtils } from './console-utils';

@Injectable({ providedIn: 'root' })
export class ModulesUtils {

    static joditBasic() {
        return { 
            buttons: 'source,|,bold,strikethrough,underline,italic,link',
            buttonsMD: 'source,|,bold,strikethrough,underline,italic,link',
            buttonsSM: 'source,|,bold,strikethrough,underline,italic,link',
            buttonsXS: 'source,|,bold,strikethrough,underline,italic,link',
            // allowTags: 'strong,em,s,a[href]',
            // cleanHTML: {
            //     allowTags: 'strong,em,s,a[href]'
            // }
        };
    }

    static joditFull() {
        return { 
            buttons: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            buttonsMD: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            buttonsSM: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            buttonsXS: 'source,|,bold,strikethrough,underline,italic,eraser,|,superscript,subscript,|,ul,ol,|,outdent,indent,|,font,fontsize,brush,paragraph,|,image,file,video,table,link,|,align,undo,redo,\n,selectall,cut,copy,paste,copyformat,|,hr,symbol,fullsize',
            uploader: { 
                insertImageAsBase64URI: true
            },
            // uploader: { 
            //     insertImageAsBase64URI: false,
            //     url: environment.apiUrl + 'upload/image',
            //     imagesExtensions: [ "jpg", "png", "jpeg", "gif"],
            //     // headers: null,
            //     // data: null,
            //     // filesVariableName: "function(e){return\"files[\"+e+\"]\"}",
            //     // withCredentials: false,
            //     // pathVariableName: "path",
            //     // format: "json",
            //     // method: "POST",
            //     // prepareData: "function(e){return e}",
            //     // isSuccess: "function(e){return e.success}",
            //     // getMessage: "function(e){return void 0!==e.data.messages&&s.isArray(e.data.messages)?e.data.messages.join(\" \"):\"\"}",
            //     // process: "function(e){return e.data}",
            //     // error: "function(e){this.j.e.fire(\"errorMessage\",e.message,\"error\",4e3)}",
            //     // defaultHandlerSuccess: "function(e){var t=this,o=this.j;s.isJoditObject(o)&&e.files&&e.files.length&&e.files.forEach((function(n,r){var i=e.isImages&&e.isImages[r]?[\"img\",\"src\"]:[\"a\",\"href\"],a=i[0],l=i[1],c=o.createInside.element(a);c.setAttribute(l,e.baseurl+n),\"a\"===a&&(c.textContent=e.baseurl+n),s.isJoditObject(t.j)&&(\"img\"===a?t.j.s.insertImage(c,null,t.j.o.imageDefaultWidth):t.j.s.insertNode(c))}))}",
            //     // defaultHandlerError: "function(e){this.j.e.fire(\"errorMessage\",e.message)}",
            //     // contentType: "function(e){return(void 0===this.j.ow.FormData||\"string\"==typeof e)&&\"application/x-www-form-urlencoded; charset=UTF-8\"}"

            // }
        };
    }

    static completeResourceByLocation(resource: Resource, form: ResourceCommonValues) {
        
        if (form.all_pages) {
            resource.all_pages = 1;
            resource.bookindex_id = null;
            resource.page_start = 0;
            resource.page_end = 0;
            resource.pages = '';
        } else if(form.in_chapter) {
            resource.all_pages = 0;
            resource.bookindex_id = form.bookindex_id;
            resource.page_start = 0;
            resource.page_end = 0;
            resource.pages = '';
        } else if(form.interval_pages) {
            resource.all_pages = 0;
            resource.bookindex_id = null;
            resource.page_start = form.page_start;
            resource.page_end = form.page_end;
            resource.pages = '';
        } else {
            resource.all_pages = 0;
            resource.bookindex_id = null;
            resource.page_start = 0;
            resource.page_end = 0;
            resource.pages = form.pages;
        }

        resource.private = form.private ? 1 : 0;
        resource.only_teachers = form.only_teachers ? 1 : 0;

        return resource;
    }

    static completeResourceFromData(resource, data) {
        resource.id = data.id;
        resource.name = data.name;
        resource.detail = data.detail;
        resource.enabled = data.enabled;
        resource.type = data.type;

        resource.file = data.file;

        resource.social_id = data.social_id;
        resource.url = data.url;

        resource.private = data.private;
        resource.only_teachers = data.only_teachers;

        resource.all_pages = data.all_pages;
        resource.bookindex_id = data.bookindex_id;
        resource.page_start = data.page_start;
        resource.page_end = data.page_end;
        resource.pages = data.pages_resource;

        resource.user_id = data.user_id;

        return resource;
    }

    static completeCommonValuesFromData(values, data) {
        values.private = data.private;
        values.only_teachers = data.only_teachers;

        values.all_pages = data.all_pages;
        values.bookindex_id = data.bookindex_id;
        values.page_start = data.page_start;
        values.page_end = data.page_end;
        values.pages = data.pages_resource;

        return values;
    }

    static getIconByType(resource) {
        switch (resource.typeName) {
            case 'Vimeo':
            case 'Youtube':
                return '<i class="fas fa-play-circle"></i>';

            case 'PDF':
                return '<i class="fas fa-file-pdf"></i>';

            case 'Documento':
                return '<i class="far fa-file-word"></i>';

            case 'Imagen':
                return '<i class="far fa-image"></i>';

            case 'URL':
                return '<i class="fas fa-link"></i>';

            default:
                return '<i class="fas fa-play-circle"></i>';
        }

    }
    
}
