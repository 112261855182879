import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideItemsActions from '@app/store/aside-item/aside-item.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ModulesUtils } from '../../helpers/modules-utils';
import { FilesManager } from '../../helpers/files-manager';
import { AppConfigService } from '../../services/app-config.service';
import { Resource } from '../../models/resource';
import { User } from '@app/shared/models';
import { ConsoleUtils } from '../../helpers/console-utils';
import { GroupsService } from '@app/shared/services/groups.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-comunication-add',
    templateUrl: './comunication-add.component.html',
    providers: []
})
export class ComunicationAddComponent implements OnDestroy {
    myConfig: any;

    validValues = false;

    editingItem: boolean;

    data: any;
    resource: Resource;
    dataLoaded = false;

    section: string;

    itemForm: FormGroup = null;
    submitted = false;
    error = '';

    configMin: any;
    configBasic: any;
    configFull: any;

    filesManager: FilesManager;

    userId: number;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private appConfig: AppConfigService,
        private groupsService: GroupsService
    ) {
        this.myConfig = this.appConfig.webConfig;
        ConsoleUtils.log('myConfig', this.myConfig);

        this.data = null;

        this.initGlobal();
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.userId = res.id;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.data).subscribe(res => {
            if (res) {
                this.data = res;

                this.dataLoaded = true;
            } else {
                ConsoleUtils.log('siempre deberia venir la información', null);
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    get f() { return this.itemForm.controls; }


    reset() {
        this.submitted = false;
        this.itemForm = null;
    }

    initGlobal() {
        this.configBasic = ModulesUtils.joditBasic();

        this.initForm();
    }


    initForm() {
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

        this.itemForm = this.formBuilder.group({
            title: ['', Validators.required],
            detail: ['', Validators.required]
        });

        this.itemForm.valueChanges.subscribe(val => {
            this.error = null;
        });
    }


    cancelSave() {
        this.store.dispatch(new AsideItemsActions.ChangeToSave(false));
        this.store.dispatch(new AsideActions.ChangeHide(true));
    }

    getErrorMessage() {
        let error = '';

        if (this.itemForm.invalid) {
            error += 'Revisa los campos oblitatorios';
        }

        return error;
    }

    onSubmit() {
        this.submitted = true;
        this.validValues = false;

        const payload = { error: null };
        this.store.dispatch(new AsideItemsActions.SetError(payload));

        if (this.itemForm.invalid) {
            ConsoleUtils.log('NO SUBMIT', null);
            ConsoleUtils.log('itemForm', this.itemForm);

            payload.error = this.getErrorMessage();
            this.store.dispatch(new AsideItemsActions.SetError(payload));

            return this.cancelSave();
        }

        // ConsoleUtils.log('salvando...', null);
        this.save();
    }

    save() {
        ConsoleUtils.log('DATA', this.data);
        const dataEmail = {
            member_id: this.data.member.id,
            group_id: this.data.group.id,
            title: this.f.title.value,
            detail: this.f.detail.value
        };
        // ConsoleUtils.log('DATA', dataEmail);

        this.groupsService.comunicationMember(dataEmail).pipe(
            first()).subscribe(data => {
                this.resetForm();

                const dataResult = { refresh: true, closeItem: true };
                this.store.dispatch(new AsideItemsActions.SetResult(dataResult));
            },
                error => {
                    const payload = { error };
                    this.store.dispatch(new AsideItemsActions.SetError(payload));
                    this.cancelSave();
                }
            );
    }


    resetForm() {
        this.f.title.setValue('');
        this.f.detail.setValue('');
        this.submitted = false;
    }


}
