import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { FilesManager } from '@app/shared/helpers/files-manager';
import { AppConfigService } from '@app/shared/services/app-config.service';
import { User } from '@app/shared/models';
import { ConsoleUtils } from '@app/shared/helpers/console-utils';
import { Group } from '../../models/group';
import { GroupsService } from '../../services/groups.service';
import { UserService } from '../../services/user.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-group-form',
    templateUrl: './group.component.html',
    providers: []
})
export class GroupComponent implements OnDestroy {
    myConfig: any;

    editingItem: boolean;

    data: any;
    group: Group;
    dataLoaded = false;

    section: string;

    itemForm: FormGroup = null;
    submitted = false;
    error = '';

    configMin: any;
    configBasic: any;
    configFull: any;

    filesManager: FilesManager;

    userId: number;

    booksSuscriptions: Array<any>;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private appConfig: AppConfigService,
        private groupService: GroupsService,
        private userService: UserService
    ) {
        this.myConfig = this.appConfig.webConfig;
        // ConsoleUtils.log('myConfig', this.myConfig);

        this.booksSuscriptions = [];
        this.data = null;
        this.initGroup();

        this.initGlobal();
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initGroup() {
        this.group = new Group();
        this.completeGroupFromUser();
    }

    completeGroupFromUser() {
        this.group.user_id = this.userId;
    }

    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.userId = res.id;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            if (res) {
                this.data = res;

                this.dataLoaded = true;
                if (this.data.group) {
                    this.fillForm();

                    this.editingItem = true;
                } else {
                    this.editingItem = false;
                    this.getSuscriptions();
                    this.completeGroupFromUser();
                }
            } else {
                this.editingItem = false;
                this.getSuscriptions();
                ConsoleUtils.log('siempre deberia venir con la informacion del libro', null);
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    get f() { return this.itemForm.controls; }


    reset() {
        this.submitted = false;
        this.itemForm = null;
    }

    initGlobal() {
        this.configBasic = ModulesUtils.joditBasic();

        this.initForm();

        if (this.data && this.data.resource) {
            this.fillForm();
        }
    }

    getSuscriptions() {
        this.userService.getSuscriptionsActive().pipe(
            first()).subscribe(data => {
                if (data.suscriptions) {
                    data.suscriptions.forEach(element => {
                        this.booksSuscriptions.push(element.book);
                    });
                }
            },
                error => {
                    ConsoleUtils.log('error con suscripciones', null);
                }
            );
    }


    initForm() {
        const reg = '^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ]+$';

        this.itemForm = this.formBuilder.group({
            enabled: [false],
            name: ['', Validators.required],
            description: ['', Validators.required],

            // code: ['', [Validators.required, Validators.pattern(reg)]],

            book_id: ['', this.editingItem ? null : Validators.required]

        });

        this.itemForm.valueChanges.subscribe(val => {
            this.error = null;
        });
    }

    fillForm() {
        const group = this.data.group;


        this.group = this.groupService.transformDataFromApi(group);

        this.itemForm.controls.enabled.setValue(group.enabled);
        this.itemForm.controls.name.setValue(group.name);
        this.itemForm.controls.description.setValue(group.description);
        this.itemForm.controls.name.setValue(group.name);
        // this.itemForm.controls.code.setValue(group.code);
        this.itemForm.controls.book_id.setValue(group.book.id);
    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    getErrorMessage() {
        let error = '';

        if (this.itemForm.invalid) {
            error += 'Revisa los campos oblitatorios';
        }

        return error;
    }

    onSubmit() {
        this.submitted = true;

        const payload = { error: null };
        this.store.dispatch(new AsideActions.SetError(payload));

        if (this.itemForm.invalid) {
            // ConsoleUtils.log('NO SUBMIT', null);
            // ConsoleUtils.log('itemForm', this.itemForm);

            payload.error = this.getErrorMessage();
            this.store.dispatch(new AsideActions.SetError(payload));
            return this.cancelSave();
        }

        // ConsoleUtils.log('salvando...', null);
        this.save();
    }

    initModule() {
        return this.group;
        // return new Resource();
    }


    create(group) {
        const payload = { result: true, create: true, group };
        this.store.dispatch(new AsideActions.SetResult(payload));
    }

    update(group) {
        const payload = { result: true, create: false, group };
        this.store.dispatch(new AsideActions.SetResult(payload));
    }

    saveByLocation() {

    }

    save() {
        const group = this.group;

        group.enabled = this.f.enabled.value ? this.f.enabled.value : false;
        group.name = this.f.name.value;
        group.description = this.f.description.value;
        // group.code = this.f.code.value;
        group.book_id = this.f.book_id.value;


        this.data.action === 'add' ? this.create(group) : this.update(group);


    }
}
