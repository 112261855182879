import { Action } from '@ngrx/store';

// STUDENTS
export const DO_GET_INFO_STUDENTS = '[Students] Get Info Students';
export class DoGetInfoStudents implements Action {
    type = DO_GET_INFO_STUDENTS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_STUDENTS_OK = '[Students] Get Info Students OK';
export class DoGetInfoStudentsOK implements Action {
    type = DO_GET_INFO_STUDENTS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_STUDENTS_KO = '[Students] Get Info Students KO';
export class DoGetInfoStudentsKO implements Action {
    type = DO_GET_INFO_STUDENTS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Students] Get Info Filters';
export class DoGetInfoStudentsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Students] Get Info Filters OK';
export class DoGetInfoStudentsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Students] Get Info Filters KO';
export class DoGetInfoStudentsFiltersKO implements Action {
    type = DO_GET_INFO_STUDENTS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Students] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoStudents | DoGetInfoStudentsOK | DoGetInfoStudentsKO |
    DoGetInfoStudentsFilters | DoGetInfoStudentsFiltersOK | DoGetInfoStudentsFiltersKO 
    | DoSetSelectedFilters
    ;
