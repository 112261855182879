export class Book {
    id: number;

    book_model: any;
    name: string;
    description: string;
    indexTxt: string;
    pages: number;
    image: string;
    category: any;
    resources: any;
    groups: any;
    enabled: boolean;

    id_suscription: number;
    expires_at: string;

    currentGroup: number;
    chaptersUser: any
}
