import { FaqCategory } from './faq-category';

export class Faq {
    _id: string;
    category: any;
    enabled: boolean;
    order: number;
    title: any;
    description: any;
    created_at: string;
    updated_at: string;
    sites: Array<string>;
    
}