import { Component } from '@angular/core';
import { RoutesService } from '@app/shared/services/routes.service';
import { environment } from '../../environments/environment';
import { AppConfigService } from '../shared/services/app-config.service';


@Component({ templateUrl: 'not-found.component.html' })
export class NotFoundComponent {


    constructor(
        public routesService: RoutesService,
        private appConfig: AppConfigService
    ) {

    }

    getLogo() {
        return '/assets/images/' + this.appConfig.webConfig.logo + '.svg';
    }
    getLogoName() {
        return this.appConfig.webConfig.logoName;
    }

    hasClassLogo() {
        return environment.webCode === 'servicios-funerarios';
    }
}
