import { Action } from '@ngrx/store';


export const SET_INFO = '[Aside Item] Set Info';
export class SetInfo implements Action {
    type = SET_INFO;
    constructor(public payload: any) { }
}

export const RESET_INFO = '[Aside Item] Reset Info';
export class ResetInfo implements Action {
    type = RESET_INFO;
    constructor() { }
}

export const SET_RESULT = '[Aside Item] Set Result';
export class SetResult implements Action {
    type = SET_RESULT;
    constructor(public payload: any) { }
}

export const CHANGE_TOSAVE = '[Aside Item] Change tosave';
export class ChangeToSave implements Action {
    type = CHANGE_TOSAVE;
    constructor(public payload: any) { }
}

export const CHANGE_HIDE_ACTIONS = '[Aside Item] Change Hide Actions';
export class ChangeHide implements Action {
    type = CHANGE_HIDE_ACTIONS;
    constructor(public payload: boolean) { }
}

export const SAVE_CHILD_ACTIONS = '[Aside Item] Save Child';
export class SaveChild implements Action {
    type = SAVE_CHILD_ACTIONS;
    constructor() { }
}

export const CANCEL_SAVE_CHILD_ACTIONS = '[Aside Item] Cancel Save Child';
export class CancelSaveChild implements Action {
    type = CANCEL_SAVE_CHILD_ACTIONS;
    constructor() { }
}

export const CLOSE_FORM_ACTIONS = '[Aside Item] Close Child';
export class CloseForm implements Action {
    type = CLOSE_FORM_ACTIONS;
    constructor() { }
}

export const RESET_CLOSE_FORM_ACTIONS = '[Aside Item] Reset Close Child';
export class ResetCloseForm implements Action {
    type = RESET_CLOSE_FORM_ACTIONS;
    constructor() { }
}

export const SET_ERROR = '[Aside Item] Set Error';
export class SetError implements Action {
    type = SET_ERROR;
    constructor(public payload: any) { }
}

export const SET_ERROR_CHILD = '[Aside Item] Set Error Child';
export class SetErrorChild implements Action {
    type = SET_ERROR_CHILD;
    constructor(public payload: any) { }
}

export const SET_MODAL_CONTENT = '[Aside Item] Set Modal Content';
export class SetModalContent implements Action {
    type = SET_MODAL_CONTENT;
    constructor(public payload: any) { }
}

export const RESET_MODAL_CONTENT = '[Aside Item] Set Modal Content';
export class ResetModalContent implements Action {
    type = RESET_MODAL_CONTENT;
    constructor() { }
}

export const SET_LABELS = '[Aside] Set Labels';
export class SetLabels implements Action {
    type = SET_LABELS;
    constructor(public payload: any) { }
}

export type All = SetInfo | SetResult | ResetInfo | ChangeToSave | ChangeHide
    | SaveChild | CloseForm | ResetCloseForm | CancelSaveChild | SetError | SetErrorChild
    | SetModalContent | ResetModalContent | SetLabels;
