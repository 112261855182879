import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ModulesUtils } from '../../../helpers/modules-utils';
import { AppConfigService } from '../../../services/app-config.service';
import { isNumber } from 'util';
import { ResourceCommonValues } from '../../../models/resourceCommonValues';

@Component({
    selector: 'app-resource-common',
    templateUrl: './common-resource.component.html',
    providers: []
})
export class CommonResourceComponent implements OnChanges {
    @Input() submitted: boolean;
    @Input() dataLoaded: boolean;
    @Input() data: any;
    @Input() myConfig: any;
    @Input() validValues: boolean;

    @Output() sendValues = new EventEmitter<any>();

    values: any;

    pages = [];
    pageAdd = '';

    errorBookIndex = false;
    errorPageStart = false;
    errorPageEnd = false;
    errorPages = false;
    errorPageAdd = false;

    constructor(
        private formBuilder: FormBuilder
    ) {
        this.initValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.data) {
            this.initData();
        }

        if (changes.validValues) {
            this.checkRequiredFields();
        }
    }

    initValues() {
        this.values = new ResourceCommonValues();
    }

    initData() {
        if (!this.data || !this.data.resource) {
            return;
        }

        const resource = this.data.resource;
        this.values.all_pages = resource.all_pages;
        this.values.in_chapter = !this.values.all_pages && resource.bookindex_id ? true : false;
        this.values.bookindex_id = (this.values.in_chapter && resource.bookindex_id) ? resource.bookindex_id : '';
        this.values.interval_pages = !this.values.all_pages && !this.values.in_chapter && resource.page_start ? true : false;
        this.values.page_start = resource.page_start;
        this.values.page_end = resource.page_end;
        this.values.pages = resource.pages_resource;

        this.values.private = resource.private ? true : false;
        this.values.only_teachers = resource.only_teachers ? true : false;

        if (resource.pages !== '') {
            this.pages = resource.pages_resource.split(',');
        }

        this.setValues();
    }

    reset() {
        this.submitted = false;
    }

    checkFieldNumber(value) {
        return value && (parseInt(value, 10) > 0);
    }

    checkFieldPages() {
        return this.pages.length ? true : false;
    }

    checkRequiredFields() {
        this.errorBookIndex = !this.values.all_pages && this.values.in_chapter && !this.values.bookindex_id;
        
        this.errorPageStart = !this.values.all_pages && !this.values.in_chapter
            && this.values.interval_pages && !this.checkFieldNumber(this.values.page_start);

        this.errorPageEnd = !this.values.all_pages && !this.values.in_chapter
            && this.values.interval_pages && !this.checkFieldNumber(this.values.page_end);

        this.errorPages = !this.values.all_pages && !this.values.in_chapter
            && !this.values.interval_pages && !this.values.pages && !this.checkFieldPages();
      
    }

    getBookIndexToSelect() {
        if (!this.data.book || !this.data.book.book_model || !this.data.book.book_model.bookindex) {
            return;
        }

        return this.data.book.book_model.bookindex;
    }

    actionIntervalPages() {
        this.values.page_start = '';
        this.values.page_end = '';
        this.values.pages = '';
        this.pages = [];

        this.setValues();
    }

    actionInChapter() {
        if (this.values.in_chapter) {
            this.values.interval_pages = false;
        } else {
            this.resetChapter();
            this.values.interval_pages = true;
        }

        this.actionIntervalPages();
    }

    actionAllPages() {
        if (this.values.all_pages) {
            this.values.in_chapter = false;
        } else {
            this.values.in_chapter = true;
        }

        this.actionInChapter();
    }

    resetChapter() {
        this.values.bookindex_id = '';
    }

    removePage(ev, index) {
        this.pages.splice(index, 1);
    }

    addPage(ev) {
        this.checkPage();
        if (this.errorPageAdd) {
            return;
        }

        this.pages.push(parseInt(this.pageAdd, 10));
        this.pages.sort(function (a, b) {
            return a - b;
        });
        this.resetPage();

        this.setValues();
    }

    checkPageInBook(page) {
        const maxPages = parseInt(this.getTotalPages(), 10);
        page = parseInt(page, 10);

        return page <= maxPages;
    }

    checkPage() {
        this.errorPageAdd = (!this.pageAdd || !this.checkFieldNumber(this.pageAdd) || !this.checkPageInBook(this.pageAdd));
    }

    resetPage() {
        this.pageAdd = '';
        this.errorPageAdd = false;
    }

    getTotalPages() {
        return this.data && this.data.book ? this.data.book.pages : '';
    }

    setValues() {
        this.values.pages = this.pages.join(',');

        this.checkRequiredFields();
        // this.submitted = true;

        this.sendValues.emit(this.values);
    }
}
