import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import * as RouterActions from './router.actions';
import { Store } from '@ngrx/store';
import { AppState } from '..';

@Injectable()
export class RouterEffects {

    lastRouterState = null;

    constructor(
        private actions$: Actions,
        private router: Router,
        private location: Location,
        private store$: Store<AppState>
    ) {
        this.store$.select(state => state.router).subscribe(
            lastRouterState => {
                this.lastRouterState = lastRouterState;
            }
        );
    }

    @Effect({ dispatch: false })
    navigate$ = this.actions$.pipe(
        ofType(RouterActions.GO),
        map((action: RouterActions.Go) => action.payload),
        tap(({ path, query: queryParams, extras }) => this.router.navigate(path, { queryParams, ...extras }))
    );

    @Effect({ dispatch: false })
    navigateBack$ = this.actions$.pipe(
        ofType(RouterActions.BACK),
        tap(() => this.location.back())
    );

    @Effect({ dispatch: false })
    navigateForward$ = this.actions$.pipe(
        ofType(RouterActions.FORWARD),
        tap(() => this.location.forward())
    );

    @Effect({ dispatch: false })
    reload$ = this.actions$.pipe(
        ofType(RouterActions.RELOAD),
        tap(() => {

            let url = window.location.href;

            // Remove old param
            url = url.replace(/(\?|&)s=[^&]*/, '');
            url += url.search(/\?/) >= 0 ? '&' : '?';
            url += 's=' + new Date().getTime();

            // Redirect
            window.location.href = url;
        })
    );
}
