import { Injectable } from '@angular/core';


@Injectable()
export class RoutesService {

    getRoute(section: string, id?: any, idParent?: any) {

        switch (section) {
            // case 'home': return '/home';
            case 'home': return '/libros';
            case 'login': return '/login';
            case 'remember': return '/recordar';

            // case 'dashboard': return '/dashboard';
            case 'dashboard': return '/libros';

            case 'books': return '/libros';
            case 'booksDetail': return '/libros/' + id;
            case 'booksDetailContent': return '/libros/' + id + '/contenido';
            case 'booksEdit': return '/libros/' + id + '/editar';

            case 'groups': return '/grupos';
            case 'groupsDetail': return '/grupos/' + id;
            case 'groupsDetailContent': return '/grupos/' + id + '/contenido';
            case 'groupsEdit': return '/grupos/' + id + '/editar';

            case 'students': return '/alumnos';

            case 'faqs': return '/contenidos/faqs';
            case 'faqsDetail': return '/contenidos/faqs/' + id;
            case 'faqsEdit': return '/contenidos/faqs/' + id + '/editar';
            case 'faqsNew': return '/contenidos/faqs/nueva';

            case 'faqsCategories': return '/contenidos/faqs/categorias';
            case 'faqsCategoriesDetail': return '/contenidos/faqs/categorias/' + id;
            case 'faqsCategoriesEdit': return '/contenidos/faqs/categorias/' + id + '/editar';
            case 'faqsCategoriesNew': return '/contenidos/faqs/categorias/nuevo';

            case 'help': return '/ayuda';

            case 'pages': return '/paginas';
            case 'pagesDetail': return '/paginas/' + id;
            case 'pagesDetailContent': return '/paginas/' + id + '/contenido';
            case 'pagesEdit': return '/paginas/' + id + '/editar';
            case 'pagesNew': return '/paginas/nuevo';
            case 'pagesComingSoon': return '/paginas/proximamente';

            case 'maqueta': return '/maqueta';

            case 'profile': return '/perfil';

            default: return '/' + section;
        }

    }
}
