import { Component } from '@angular/core';
import { Router } from '@angular/router';

declare var noScroll: any;

@Component({
    templateUrl: 'andres.component.html'
})

export class AndresComponent {

    isCollapseAside: boolean;
    isMobileMenuOpen: boolean;
    openAsideConfig: boolean;

    constructor(
        private router: Router
    ) {}

    clickAsideCollapsed() {
        this.isCollapseAside = !this.isCollapseAside;
    }

    clickHamburger(ev) {
        ev.preventDefault();

        // Lock body scroll
        noScroll.on();

        // Open mobile menu
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
    }

    clickBackdrop() {
        // Lock body scroll
        noScroll.off();

        // Hide mobile menu
        this.isMobileMenuOpen = false;
        this.openAsideConfig = false;
    }

    clickDashboard(ev) {
        ev.preventDefault();

        this.router.navigate(['/dashboard'], {});

        // Lock body scroll
        noScroll.off();

        // Hide mobile menu
        this.isMobileMenuOpen = false;
    }

    clickExpedientes(ev) {
        ev.preventDefault();

        this.router.navigate(['/expedientes'], {});

        // Lock body scroll
        noScroll.off();

        // Hide mobile menu
        this.isMobileMenuOpen = false;
    }

    clickMaquetas(ev) {
        ev.preventDefault();

        this.router.navigate(['/maquetas'], {});

        // Lock body scroll
        noScroll.off();

        // Hide mobile menu
        this.isMobileMenuOpen = false;
    }

    clickProductos(ev) {
        ev.preventDefault();

        this.router.navigate(['/productos'], {});

        // Lock body scroll
        noScroll.off();

        // Hide mobile menu
        this.isMobileMenuOpen = false;
    }

    displayConfigPanel(ev) {
        ev.preventDefault();

        // Hide mobile menu
        this.openAsideConfig = !this.openAsideConfig;
    }
}