import { Action } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';

export const GO = '[Router] Go';
export class Go implements Action {
    readonly type = GO;

    constructor(public payload: {
        path: any[];
        query?: object;
        extras?: NavigationExtras;
    }) { }
}

export const BACK = '[Router] Back';
export class Back implements Action {
    readonly type = BACK;
}

export const FORWARD = '[Router] Forward';
export class Forward implements Action {
    readonly type = FORWARD;
}

export const RELOAD = '[Router] Reload';
export class Reload implements Action {
    readonly type = RELOAD;
}

export type RouterActionsUnion = Go | Back | Forward | Reload;
