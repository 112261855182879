import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as productsActions from './students.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { StudentsService } from '../../shared/services/students.service';
import { DO_GET_INFO_FILTERS } from './students.actions';
import { GroupsService } from '@app/shared/services/groups.service';




@Injectable()
export class StudentsEffects {

    constructor(
        private studentsService: StudentsService,
        private groupsService: GroupsService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoStudents$: Observable<Action> = this.actions$.pipe(
        ofType(productsActions.DO_GET_INFO_STUDENTS),
        switchMap((action: productsActions.DoGetInfoStudents) => {
            return this.studentsService.get(action).pipe(
                map( response => new productsActions.DoGetInfoStudentsOK(response)),
                catchError(error => of(new productsActions.DoGetInfoStudentsKO(error)))
            );
        }));

    @Effect()
    DoGetInfoStudentsFilters$: Observable<Action> = this.actions$.pipe(
        ofType(productsActions.DO_GET_INFO_FILTERS),
        switchMap((action: productsActions.DoGetInfoStudentsFilters) => {
            return this.groupsService.getFilters().pipe(
                map( response => new productsActions.DoGetInfoStudentsFiltersOK(response)),
                catchError(error => of(new productsActions.DoGetInfoStudentsFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(productsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: productsActions.DoSetSelectedFilters) => {
            return this.studentsService.get(action).pipe(
                map( response => new productsActions.DoGetInfoStudentsOK(response)),
                catchError(error => of(new productsActions.DoGetInfoStudentsKO(error)))
            );
        }));
}
