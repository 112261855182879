import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import { environment } from '@environments/environment';
import { User } from '@app/shared/models';
import { Credentials, CredentialsHelper } from '../models/credentials';

import { map } from 'rxjs/operators';

// Utils
import { getAuthString, Base64, cifrarXor } from '../lib/helpers/utils';
import { Md5 } from 'ts-md5/dist/md5';

import { Store } from '@ngrx/store';
import { AppState } from '../../store/index';
import { AuthState } from '../../store/auth/auth.state';
import * as loggedActions from '../../store/auth/auth.actions';
import { RouterStateSnapshot } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
    // private currentUserSubject: BehaviorSubject<User>;
    // public currentUser: Observable<User>;

    initialized: boolean;

    loggedUser$: Observable<AuthState>;
    authdata: string;
    authdataCredentials: string;
    token: string;
    logged: boolean;
    user: User;

    credentials: any = {
        email: 'resetPass',
        password: 'rPeass'
    };

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private http: HttpClient,
        private store: Store<AppState>
    ) {
        this.initialized = false;

        this.logged = false;

        this.token = this.getToken();

        this.initSuscriptions();

        
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push( this.store.select(state => state.auth.user).subscribe( res => {
            this.user = res;
        }));
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    public get authData(): string {
        return this.authdata;
    }

    public get authDataCredentials(): string {
        return this.authdataCredentials;
    }

    public get authToken(): string {
        return this.token;
    }

    public isLogged(): boolean {
        if (!this.initialized) {
            this.initAutoLogin();
        }

        const token = this.getToken();
        return token ? true : false;
    }

    public initAutoLogin(): void {

        let token = sessionStorage.getItem('authToken');
        if (token && token.length < 10) {
            token = '';
        }

        if (!(token)) {
            this.store.dispatch(
                new loggedActions.InitApp()
            );
        } else {
            this.store.dispatch(
                new loggedActions.AutoLogin()
            );
        }

        this.initialized = true;
    }

    // public isLogged(): boolean {
    //     if (!this.initialized) {
    //         this.initAutoLogin();
    //     }

    //     const authData = this.getAuthData();
    //     // console.log('token isLogged', token);
    //     return authData ? true : false;
    // }

    // public initAutoLogin(): void
    // {
    //     let authData = sessionStorage.getItem('authData');
    //     if (authData && authData.length < 5) {
    //         authData = '';
    //     }

    //     if (!(authData)) {
    //         this.store.dispatch(
    //             new loggedActions.InitApp()
    //         );
    //     } else {
    //         this.store.dispatch(
    //             new loggedActions.AutoLogin(authData)
    //         );
    //     }

    //     this.initialized = true;
    // }

    autoLogin() {
        // return this.http.get<User>(`${environment.apiUrl}/auth`);
        return this.http.get<User>(`${environment.apiUrl}/user`);
    }

    login(credentials: Credentials) {
        const user = credentials.user;
        const password = credentials.password;

        return this.http.post<any>(`${environment.apiUrl}/auth`, { user, password });
    }

    logout() {
        // remove user from local storage to log user out
        // sessionStorage.removeItem('currentUser');
        // this.currentUserSubject.next(null);
    }

    getUser() {
        return this.http.get<any>(`${environment.apiUrl}/user`);
    }

    getToken(): string {
        return sessionStorage.getItem('authToken');
    }

    deleteToken(): void {
        sessionStorage.removeItem('authToken');
    }

    deleteUser(): void {
        sessionStorage.removeItem('user');
    }

    /**
     * Save token in sessionStorage
     *
     * @param string authToken
     * @param string userMd5
     *
     * @memberOf SoledadAuthenticationService
     */
    saveToken(authToken: string): void {
        this.token = authToken;
        sessionStorage.setItem('authToken', authToken);
    }
    // saveToken(authToken: string, user: any): void {
    //     console.log('salvando token', authToken);
    //     sessionStorage.setItem('authToken', authToken);
    //     sessionStorage.setItem('user', JSON.stringify(user));
    // }

    // saveUser(user: any) {
    //     // console.log('user to save', user);
    //     sessionStorage.setItem('user', JSON.stringify(user));
    // }

    /**
     * Save token in localStorage
     *
     * @param string authToken
     *
     * @memberOf SoledadAuthenticationService
     */
    saveTokenLocalStorage(authToken: string): void {
        localStorage.setItem('authToken', authToken);
    }

    saveAccessDataLocalStorage(credential: Credentials): void {
        // console.log('saveAccessDataLocalStorage', credential);

        const cred: any = {
            login: credential.user,
            password: credential.password
        };

        const encode: string = Base64.encode(
            cifrarXor(JSON.stringify(cred), this.credentials.publicKey)
        );
        localStorage.setItem('credentials', encode);
    }

    /**
     * @param  {string} encryptedCredentials encryptedCredentials The encrypted credentials
     * @return {Credentials} Credentials decrypted
     */
    decryptCredentials(encryptedCredentials: string): Credentials {
        const aux = cifrarXor(
            Base64.decode(encryptedCredentials),
            this.credentials.publicKey
        );
        return CredentialsHelper.newFromJSON(aux);
    }
}
