import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as productsActions from './pages.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { PagesService } from '../../shared/services/pages.service';
import { DO_GET_INFO_FILTERS } from './pages.actions';




@Injectable()
export class PagesEffects {

    constructor(
        private productsService: PagesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoPages$: Observable<Action> = this.actions$.pipe(
        ofType(productsActions.DO_GET_INFO_PAGES),
        switchMap((action: productsActions.DoGetInfoPages) => {
            return this.productsService.get(action).pipe(
                map( response => new productsActions.DoGetInfoPagesOK(response)),
                catchError(error => of(new productsActions.DoGetInfoPagesKO(error)))
            );
        }));

    @Effect()
    DoGetInfoPagesFilters$: Observable<Action> = this.actions$.pipe(
        ofType(productsActions.DO_GET_INFO_FILTERS),
        switchMap((action: productsActions.DoGetInfoPagesFilters) => {
            return this.productsService.getFilters().pipe(
                map( response => new productsActions.DoGetInfoPagesFiltersOK(response)),
                catchError(error => of(new productsActions.DoGetInfoPagesFiltersKO(error)))
            );
        }));


    @Effect()
    DoSetSelectedFilters$: Observable<Action> = this.actions$.pipe(
        ofType(productsActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: productsActions.DoSetSelectedFilters) => {
            return this.productsService.get(action).pipe(
                map( response => new productsActions.DoGetInfoPagesOK(response)),
                catchError(error => of(new productsActions.DoGetInfoPagesKO(error)))
            );
        }));
}
