import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User } from '@app/shared/models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    get() {
        return this.http.get<User>(`${environment.apiUrl}/user`);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getSuscriptionsActive() {
        return this.http.get<any>(`${environment.apiUrl}/user/suscriptions-active`);
    }

    transformDataFromApi(data: any) {
        const user = new User();

        user.id = data.id;
        user.name = data.name;
        user.user = data.user;
        user.phone = data.phone;
        user.avatar = data.avatar;
        user.center = data.center;
        user.created_at = data.created_at.date ? data.created_at.date : data.created_at;
        user.updated_at = data.updated_at;

        user.group = data.group ? data.group : null;

        return user;

    }

    transformListFromApi(list: Array<any>) {

        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        return data;

    }

    isTeacher(currentUser: User) {
        return (currentUser && currentUser.group.id == 2);

    }
}
