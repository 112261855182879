import { Action } from '@ngrx/store';

// BOOKS
export const DO_GET_INFO_BOOKS = '[Books] Get Info Books';
export class DoGetInfoBooks implements Action {
    type = DO_GET_INFO_BOOKS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_BOOKS_OK = '[Books] Get Info Books OK';
export class DoGetInfoBooksOK implements Action {
    type = DO_GET_INFO_BOOKS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_BOOKS_KO = '[Books] Get Info Books KO';
export class DoGetInfoBooksKO implements Action {
    type = DO_GET_INFO_BOOKS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Books] Get Info Filters';
export class DoGetInfoBooksFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Books] Get Info Filters OK';
export class DoGetInfoBooksFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Books] Get Info Filters KO';
export class DoGetInfoBooksFiltersKO implements Action {
    type = DO_GET_INFO_BOOKS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Books] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoBooks | DoGetInfoBooksOK | DoGetInfoBooksKO |
    DoGetInfoBooksFilters | DoGetInfoBooksFiltersOK | DoGetInfoBooksFiltersKO 
    | DoSetSelectedFilters
    ;
