import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as authActions from './auth.actions';

import { AuthenticationService } from '../../shared/services/authentication.service';
import { Router } from '@angular/router';

import { RoutesService } from '../../shared/services/routes.service';
import { Md5 } from 'ts-md5/dist/md5';

import { AppState } from '../index';
import * as LayoutActions from '../layout/layout.actions';
import { UserService } from '../../shared/services/user.service';

@Injectable()
export class AuthEffects {

    constructor(
        private authenticationService: AuthenticationService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService,
        private usersService: UserService,
        private store$: Store<AppState>
    ) { }

    @Effect()
    AutoLogin$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.AUTO_LOGIN),
        switchMap((action: authActions.AutoLogin) => {
            return this.authenticationService.autoLogin().pipe(
                map( response => new authActions.AutoLoginSuccess(response)),
                catchError(error => of(new authActions.AutoLoginFail(error)))
            );
        }));

    @Effect({ dispatch: false })
    AutoLoginSuccess: Observable<any> = this.actions$.pipe(
        ofType(authActions.AUTO_LOGIN_SUCCESS),
        tap((data) => {
            // this.store$.dispatch(new authActions.SetUser());
            return true;
        })
    );

    @Effect({ dispatch: false })
    AutoLoginFail: Observable<any> = this.actions$.pipe(
        ofType(authActions.AUTO_LOGIN_FAIL),
        tap(() => {
            this.authenticationService.deleteToken();
            this.router.navigate([this.routesService.getRoute('login')], {});
            return false;
        })
    );

    @Effect()
    DoLogin$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.DO_LOGIN),
        switchMap((action: authActions.DoLogin) => {
            return this.authenticationService.login(action.payload).pipe(
                map( response => new authActions.DoLoginSuccess(response)),
                catchError(error => of(new authActions.DoLoginFail(error)))
            );
        }));

    @Effect({ dispatch: false })
    DoLoginSuccess: Observable<any> = this.actions$.pipe(
        ofType(authActions.DO_LOGIN_SUCCESS),
        tap((data) => {
            this.authenticationService.saveToken(data.payload.token);
            this.store$.dispatch(new authActions.SetUser());

            // this.router.navigate([this.routesService.getRoute('dashboard')], {});
            this.router.navigate([this.routesService.getRoute('books')], {});
        })
    );

    @Effect({ dispatch: false })
    DoLogout$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.DO_LOGOUT),
        tap((data) => {
            this.authenticationService.deleteToken();
            this.store$.dispatch(new LayoutActions.CloseAsideConfig());
            this.router.navigate([this.routesService.getRoute('login')], {});
        })
    );

    @Effect()
    SetUser$: Observable<Action> = this.actions$.pipe(
        ofType(authActions.SET_USER),
        switchMap((action: authActions.SetUser) => {
            return this.authenticationService.getUser().pipe(
                map( response => {
                    response.user = this.usersService.transformDataFromApi(response.user);
                    return new authActions.SetUserSuccess(response);
                }),
                catchError(error => of(new authActions.SetUserFail(error)))
            );
        }));
}
