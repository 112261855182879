import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

import * as faqsCategoriesActions from './faqs-categories.actions';

import { Router } from '@angular/router';
import { RoutesService } from '../../shared/services/routes.service';
import { FaqsCategoriesService } from '../../shared/services/faqs-categories.service';



@Injectable()
export class FaqsCategoriesEffects {

    constructor(
        private faqsCategoriesService: FaqsCategoriesService,
        private actions$: Actions,
        private router: Router,
        private routesService: RoutesService
    ) { }

    @Effect()
    DoGetInfoFaqsCategories$: Observable<Action> = this.actions$.pipe(
        ofType(faqsCategoriesActions.DO_GET_INFO_FAQS_CATEGORIES),
        switchMap((action: faqsCategoriesActions.DoGetInfoFaqsCategories) => {
            return this.faqsCategoriesService.get(action).pipe(
                map( response => new faqsCategoriesActions.DoGetInfoFaqsCategoriesOK(response)),
                catchError(error => of(new faqsCategoriesActions.DoGetInfoFaqsCategoriesKO(error)))
            );
        }));

    @Effect()
    DoSetSelectedFiltersCategories$: Observable<Action> = this.actions$.pipe(
        ofType(faqsCategoriesActions.DO_SET_SELECTED_FILTERS),
        switchMap((action: faqsCategoriesActions.DoSetSelectedFilters) => {
            return this.faqsCategoriesService.get(action).pipe(
                map( response => new faqsCategoriesActions.DoGetInfoFaqsCategoriesOK(response)),
                catchError(error => of(new faqsCategoriesActions.DoGetInfoFaqsCategoriesKO(error)))
            );
        }));
}
