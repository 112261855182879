import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';


// Router
import { LayoutState } from './layout/layout.state';
import { LayoutReducer } from './layout/layout.reducer';
import { LayoutEffects } from './layout/layout.effects';

import { RouterEffects } from './router/router.effects';
import { RouterState } from './router/router.state';

import { AuthEffects } from './auth/auth.effects';
import { AuthReducer } from './auth/auth.reducer';
import { AuthState } from './auth/auth.state';

import { PagesReducer } from './pages/pages.reducer';
import { PagesState } from './pages/pages.state';
import { PagesEffects } from './pages/pages.effects';

import { AsideEffects } from './aside/aside.effects';
import { AsideState } from './aside/aside.state';
import { AsideReducer } from './aside/aside.reducer';

import { AsideItemEffects } from './aside-item/aside-item.effects';
import { AsideItemState } from './aside-item/aside-item.state';
import { AsideItemReducer } from './aside-item/aside-item.reducer';

import { FaqsEffects } from './faqs/faqs.effects';
import { FaqsCategoriesEffects } from './faqs-categories/faqs-categories.effects';
import { FaqsState } from './faqs/faqs.state';
import { FaqsCategoriesState } from './faqs-categories/faqs-categories.state';
import { FaqsReducer } from './faqs/faqs.reducer';
import { FaqsCategoriesReducer } from './faqs-categories/faqs-categories.reducer';
import { BooksEffects } from './books/books.effects';
import { BooksState } from './books/books.state';
import { BooksReducer } from './books/books.reducer';

import { GroupsEffects } from './groups/groups.effects';
import { GroupsState } from './groups/groups.state';
import { GroupsReducer } from './groups/groups.reducer';
import { StudentsEffects } from './students/students.effects';
import { StudentsState } from './students/students.state';
import { StudentsReducer } from './students/students.reducer';
import { ViewerState } from './viewer/viewer.state';
import { ViewerReducer } from './viewer/viewer.reducer';
import { ViewerEffects } from './viewer/viewer.effects';

export const effects = [
    RouterEffects,
    LayoutEffects,
    AuthEffects,

    AsideEffects,
    AsideItemEffects,

    PagesEffects,

    BooksEffects,

    GroupsEffects,

    StudentsEffects,

    FaqsEffects,
    FaqsCategoriesEffects,

    ViewerEffects
];

export interface AppState {
    layout: LayoutState;
    router: RouterReducerState<RouterState>;

    aside: AsideState;
    asideItem: AsideItemState;

    auth: AuthState;

    pages: PagesState;

    books: BooksState;

    groups: GroupsState;

    students: StudentsState;

    faqs: FaqsState;
    faqsCategories: FaqsCategoriesState;

    viewer: ViewerState;

}


export const reducers: ActionReducerMap<AppState> = {
    layout: LayoutReducer,
    router: routerReducer,

    aside: AsideReducer,
    asideItem: AsideItemReducer,

    auth: AuthReducer,

    pages: PagesReducer,

    books: BooksReducer,

    groups: GroupsReducer,

    students: StudentsReducer,

    faqs: FaqsReducer,
    faqsCategories: FaqsCategoriesReducer,

    viewer: ViewerReducer

};

