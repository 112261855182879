import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef  } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import * as LayoutActions from '@app/store/layout/layout.actions';
import * as AsideItemActions from '@app/store/aside-item/aside-item.actions';
import { SaveChild, ResetModalContent } from '../../../store/aside-item/aside-item.actions';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ResourcesService } from '@app/shared/services/resources.service';
import { first } from 'rxjs/operators';
import { ConsoleUtils } from '@app/shared/helpers/console-utils';
import { environment } from '@environments/environment';
// import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

declare var noScroll: any;

@Component({
    selector: 'app-modal-resource',
    templateUrl: './modal-resource.component.html',
})
export class ModalResourceComponent implements OnDestroy {
    openModalResource: boolean;

    modalContent: any;
    hasBtnOnFooter: boolean;
    isSaving: boolean;
    hideActions: boolean;

    title: string;
    mode: string;
    fileData: any;

    public documentPdf: string = null;

    public viewer;
    public service: string = 'https://www.edicioneseo.es:6001/api/pdfviewer';


    @ViewChild('iframe', {static: false}) iframe: ElementRef;   

    
    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private resourcesService: ResourcesService
    ) {

        this.initSuscriptions();

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.layout.openModalResource).subscribe(res => {
            this.openModalResource = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.asideItem.modalContent).subscribe(res => {
            this.modalContent = res;
            
            if (this.openModalResource && this.modalContent) {
                this.init();
            }
        }));
    }

    init() {
        this.title = this.modalContent.name;
        this.mode = this.modalContent.typeName;
        // ConsoleUtils.log('mode init', this.mode);
        // ConsoleUtils.log('modalContent', this.modalContent);

        if (this.mode === 'PDF' || this.mode === 'Imagen' || this.mode === 'Documento') {
            this.getFile();
        }
        // this.hasBtnOnFooter = false;
    }

    reset() {
        this.mode = '';
        this.title = '';
    }

    close() {
        this.reset();

        this.store.dispatch(new AsideActions.ChangeHide(false));
        this.store.dispatch(new AsideItemActions.ResetModalContent());
        this.store.dispatch(new LayoutActions.CloseModalResource());
    }

    getFile() {
        // console.log('this.modalContent',this.modalContent);
        this.resourcesService.open(this.modalContent.book_id, this.modalContent.id).pipe(
            first()).subscribe(data => {                
                this.fileData = data.file;
                this.fileData = 'data:application/pdf;base64,' + this.fileData;
                
                this.documentPdf = data.file;
            });
    }

    downloadPdf() {
        const linkSource = 'data:application/pdf;base64,' + this.fileData;
        const downloadLink = document.createElement("a");
        const fileName = "sample.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    getPdfFile() {
        // console.log('this.modalContent', this.modalContent);
        // console.log('getPdfFile /storage/resources/', this.modalContent.file);
        return '/storage/resources/' + this.modalContent.file;
    }
}
