import { Component, OnInit, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, fromEventPattern, Subscription } from 'rxjs';
import { AppState } from '../../../store/index';
import * as LayoutActions from '../../../store/layout/layout.actions';
import * as AsideActions from '@app/store/aside/aside.actions';

import { environment } from '@environments/environment';
import { FormGroup, FormBuilder } from '@angular/forms';

declare var noScroll: any;

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  host: {'class': 'main-panel'}
})
export class WrapperComponent implements OnDestroy {

  isCollapseAside: boolean;
  isCollapseAsideResources: boolean;
  isMobileMenuOpen: boolean;
  openAsideConfig: boolean;
  openAsideSecondary: boolean;
  openAsideItems: boolean;
  openModalResource: boolean;

  languages: Array<any>;

  private suscriptionsToManage$: Subscription[] = [];

  constructor(
    private store: Store<AppState>
  ) {

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.isCollapseAside).subscribe(res => {
      this.isCollapseAside = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.isCollapseAsideResources).subscribe(res => {
      this.isCollapseAsideResources = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.isMobileMenuOpen).subscribe(res => {
      this.isMobileMenuOpen = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideConfig).subscribe(res => {
        this.openAsideConfig = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideSecondary).subscribe(res => {
        this.openAsideSecondary = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openAsideItems).subscribe(res => {
        this.openAsideItems = res;
    }));

    this.suscriptionsToManage$.push(this.store.select(state => state.layout.openModalResource).subscribe(res => {
        this.openModalResource = res;
    }));
  }

  ngOnDestroy() {
      this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
  }

  clickBackdrop() {
    // Lock body scroll
    console.log('click blackdrop');
    if (!this.openAsideItems) {
      noScroll.off();

      this.store.dispatch(new LayoutActions.CloseBackdrop());
      this.store.dispatch(new AsideActions.ResetInfo());
    }
  }
}
