import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ModulesUtils } from '../../../helpers/modules-utils';
import { FilesManager } from '../../../helpers/files-manager';
import { AppConfigService } from '../../../services/app-config.service';
import { Resource } from '../../../models/resource';
import { ResourceCommonValues } from '../../../models/resourceCommonValues';
import { User } from '@app/shared/models';
import { ConsoleUtils } from '../../../helpers/console-utils';

@Component({
    selector: 'app-resource-pdf',
    templateUrl: './resource-pdf.component.html',
    providers: []
})
export class ResourcePdfComponent implements OnDestroy {
    myConfig: any;

    commonValues: ResourceCommonValues;
    validValues = false;

    editingItem: boolean;

    data: any;
    resource: Resource;
    dataLoaded = false;

    section: string;

    itemForm: FormGroup = null;
    submitted = false;
    error = '';

    configMin: any;
    configBasic: any;
    configFull: any;

    filesManager: FilesManager;

    userId: number;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private appConfig: AppConfigService
    ) {
        this.myConfig = this.appConfig.webConfig;
        ConsoleUtils.log('myConfig', this.myConfig);

        this.data = null;
        this.initResource();

        this.initGlobal();
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initResource() {
        this.commonValues = new ResourceCommonValues();

        this.resource = new Resource();

        this.resource.destination = 2;
        this.resource.type = 1;
    }

    completeResourceFromBook() {
        this.resource.book_model_id = this.data.book.book_model.id;
        this.resource.book_id = this.data.book.id;
    }

    completeResourceFromUser() {
        this.resource.user_id = this.userId;
    }

    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.userId = res.id;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            if (res) {
                this.data = res;

                this.completeResourceFromBook();

                this.dataLoaded = true;
                if (this.data.resource) {
                    this.fillForm();

                    this.editingItem = true;
                } else {
                    this.editingItem = false;
                    this.completeResourceFromUser();
                }
            } else {
                ConsoleUtils.log('siempre deberia venir con la informacion del libro', null);
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    get f() { return this.itemForm.controls; }


    reset() {
        this.submitted = false;
        this.itemForm = null;
    }

    initGlobal() {
        this.configBasic = ModulesUtils.joditBasic();

        this.initFormImages();
        this.initForm();

        if (this.data && this.data.resource) {
            this.fillForm();
        }
    }

    initFormImages() {
        const formImages = [
            {
                field: 'file',
                initial_value: null,
                validators: {
                    max_size: 40971520,
                    allowed_types: 'application/pdf',
                    allowed_types_txt: 'PDF',
                    max_height: 108000,
                    max_width: 192000,
                    is_required: true
                },
                base64: null,
                error: null,
                saved: false,
                file_name: ''
            }
        ];

        this.filesManager = new FilesManager();
        this.filesManager.setConfiguration(formImages);
    }

    initForm() {
        this.itemForm = this.formBuilder.group({
            name: ['', Validators.required],
            detail: ['', Validators.required],

            all_pages: [true],
            in_chapter: [true],
            interval_pages: [true],
            bookindex_id: [''],
            page_start: [''],
            page_end: [''],
            pages: [''],

        });

        this.itemForm.valueChanges.subscribe(val => {
            this.error = null;
        });
    }

    fillForm() {
        const resource = this.data.resource;

        this.resource = ModulesUtils.completeResourceFromData(this.resource, resource);
        this.commonValues = ModulesUtils.completeCommonValuesFromData(this.commonValues, resource);

        this.itemForm.controls.name.setValue(resource.name);
        this.itemForm.controls.detail.setValue(resource.detail);
        this.itemForm.controls.name.setValue(resource.name);

        this.itemForm.controls.all_pages.setValue(resource.all_pages);
        if (resource.bookindex_id) {
            this.itemForm.controls.bookindex_id.setValue(resource.bookindex_id);
        }

        this.itemForm.controls.name.setValue(resource.name);
        this.itemForm.controls.page_start.setValue(resource.page_start);
        this.itemForm.controls.page_end.setValue(resource.page_end);
        this.itemForm.controls.pages.setValue(resource.pages);

        this.filesManager.setProperties('file', { initial_value: resource.file ? resource.file : null });
    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    getErrorMessage(validFiles, validCommonValues) {
        let error = '';

        if (this.itemForm.invalid && !validFiles) {
            error += 'Revisa los campos oblitatorios y el archivo';
        } else if (this.itemForm.invalid) {
            error += 'Revisa los campos obligatorios';
        } else if (!validFiles) {
            error += 'Error en el archivo';
        } else {
            error += 'Revisa los campos oblitatorios';
        }

        return error;
    }

    onSubmit() {
        this.submitted = true;
        this.validValues = false;

        const payload = { error: null };
        this.store.dispatch(new AsideActions.SetError(payload));

        const validFile = this.filesManager.isValidFile('file', !this.editingItem ? 'add' : 'edit');
        this.filesManager.isValidFile('file', !this.editingItem ? 'add' : 'edit');

        const validCommonValues = this.validCommonValues();

        if (this.itemForm.invalid || !validFile || !validCommonValues) {
            ConsoleUtils.log('NO SUBMIT', null);
            ConsoleUtils.log('itemForm', this.itemForm);
            ConsoleUtils.log('validFile', validFile);
            ConsoleUtils.log('validCommon', validCommonValues);

            payload.error = this.getErrorMessage(validFile, validCommonValues);
            this.store.dispatch(new AsideActions.SetError(payload));
            return this.cancelSave();
        }

        ConsoleUtils.log('salvando...', null);
        this.save();
    }

    initModule() {
        return this.resource;
        // return new Resource();
    }


    create(resource) {
        const payload = { result: true, create: true, resource, action: 'addResource' };
        this.store.dispatch(new AsideActions.SetResult(payload));
    }

    update(resource) {
        const payload = { result: true, create: false, resource };
        this.store.dispatch(new AsideActions.SetResult(payload));
    }

    saveByLocation() {

    }

    save() {
        let resource = this.resource;

        resource.name = this.f.name.value;
        resource.detail = this.f.detail.value;

        const newFile = this.filesManager.getBase64Image('file');
        if (newFile) {
            resource.file = this.filesManager.getBase64Image('file');
            resource.file_name = this.filesManager.getFileName('file');
        }

        resource = ModulesUtils.completeResourceByLocation(resource, this.commonValues);

        this.data.action === 'add' ? this.create(resource) : this.update(resource);


    }

    setValues(values: any) {
        ConsoleUtils.log('set values in pdf', values);
        this.commonValues = values;
    }

    validCommonValues() {
        this.validValues = true;

        if (this.commonValues.all_pages) {
            return true;
        }

        if (this.commonValues.in_chapter) {
            return this.commonValues.bookindex_id;
        }

        if (this.commonValues.interval_pages) {
            return this.commonValues.page_start && this.commonValues.page_end;
        }

        return this.commonValues.pages !== '';
    }

    openResource(file) {
        return '/storage/resources/' + file;
    }
}
