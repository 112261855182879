import { Action } from '@ngrx/store';

// GROUPS
export const DO_GET_INFO_GROUPS = '[Groups] Get Info Groups';
export class DoGetInfoGroups implements Action {
    type = DO_GET_INFO_GROUPS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GROUPS_OK = '[Groups] Get Info Groups OK';
export class DoGetInfoGroupsOK implements Action {
    type = DO_GET_INFO_GROUPS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_GROUPS_KO = '[Groups] Get Info Groups KO';
export class DoGetInfoGroupsKO implements Action {
    type = DO_GET_INFO_GROUPS_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Groups] Get Info Filters';
export class DoGetInfoGroupsFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Groups] Get Info Filters OK';
export class DoGetInfoGroupsFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Groups] Get Info Filters KO';
export class DoGetInfoGroupsFiltersKO implements Action {
    type = DO_GET_INFO_GROUPS_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Groups] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoGroups | DoGetInfoGroupsOK | DoGetInfoGroupsKO |
    DoGetInfoGroupsFilters | DoGetInfoGroupsFiltersOK | DoGetInfoGroupsFiltersKO 
    | DoSetSelectedFilters
    ;
