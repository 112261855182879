import { Component, Input, OnDestroy, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppState } from '@app/store/index';
import { Store } from '@ngrx/store';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import * as AsideActions from '@app/store/aside/aside.actions';
import { ModulesUtils } from '@app/shared/helpers/modules-utils';
import { FilesManager } from '@app/shared/helpers/files-manager';
import { AppConfigService } from '@app/shared/services/app-config.service';
import { User } from '@app/shared/models';
import { ConsoleUtils } from '@app/shared/helpers/console-utils';
import { Group } from '../../models/group';
import { GroupsService } from '../../services/groups.service';
import { UserService } from '../../services/user.service';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-student-form',
    templateUrl: './student.component.html',
    providers: []
})
export class StudentComponent implements OnDestroy {
    myConfig: any;

    data: any;
    student: any;
    dataLoaded = false;

    section: string;

    itemForm: FormGroup = null;
    submitted = false;
    error = '';
    errorCheck = '';

    dataValidate = null;

    userId: number;

    booksSuscriptions: Array<any>;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        private store: Store<AppState>,
        private formBuilder: FormBuilder,
        private appConfig: AppConfigService,
        private groupService: GroupsService,
        private userService: UserService
    ) {
        this.myConfig = this.appConfig.webConfig;

        this.data = null;
        this.initStudent();

        this.initGlobal();
        this.initSuscriptions();
    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

    initStudent() {
        this.student = {
            id: null,
            group: null,
            is_teacher: 0
        };
    }

    initSuscriptions() {

        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.userId = res.id;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.section).subscribe(res => {
            this.section = res;
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.data).subscribe(res => {
            if (res) {
                this.data = res;

                this.dataLoaded = true;
                if (this.data.group) {
                    this.student.group = this.data.group;
                }
                if (this.data.is_teacher) {
                    this.student.is_teacher = this.data.is_teacher;
                }
            } else {
                ConsoleUtils.log('siempre deberia venir con la informacion del libro', null);
            }
        }));

        this.suscriptionsToManage$.push(this.store.select(state => state.aside.toSave).subscribe(res => {
            if (res) {
                this.onSubmit();
            }
        }));
    }

    get f() { return this.itemForm.controls; }


    reset() {
        this.submitted = false;
        this.itemForm = null;
    }

    initGlobal() {
        this.initForm();
    }

    initForm() {
        this.itemForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]

        });

        this.itemForm.valueChanges.subscribe(val => {
            this.error = null;
        });
    }

    cancelSave() {
        this.store.dispatch(new AsideActions.CancelToSave());
    }

    getErrorMessage() {
        let error = '';

        if (this.itemForm.invalid) {
            error += 'Revisa los campos oblitatorios';
        }

        return error;
    }

    onSubmit() {
        this.submitted = true;

        const payload = { error: null };
        this.store.dispatch(new AsideActions.SetError(payload));

        if (this.itemForm.invalid) {
            ConsoleUtils.log('NO SUBMIT', null);
            ConsoleUtils.log('itemForm', this.itemForm);

            payload.error = this.getErrorMessage();
            this.store.dispatch(new AsideActions.SetError(payload));
            return this.cancelSave();
        }

        ConsoleUtils.log('salvando...', null);
        this.save();
    }

    initModule() {
        return this.student;
    }


    create(student) {
        const payload = { result: true, create: true, student, action: 'addStudent' };
        this.store.dispatch(new AsideActions.SetResult(payload));
    }

    save() {
        const student = this.student;

        student.id = this.dataValidate.user.id;

        this.create(student);
    }

    resetDataValidate() {
        this.dataValidate = null;
        this.errorCheck = null;
        this.store.dispatch(new AsideActions.ChangeHide(true));
    }

    resetErrorCheck(ev) {
        this.errorCheck = null;
    }

    checkEmail() {
        this.submitted = true;
        this.errorCheck = null;

        if (this.itemForm.invalid) {
            return;
        }

        const data = {
            group_id: this.data.group.id,
            book_id: this.data.group.book.id,
            email: this.f.email.value
        };
        ConsoleUtils.log('data', data);
        this.groupService.checkUserToAdd(data).pipe(
            first()).subscribe(data => {
                this.store.dispatch(new AsideActions.ChangeHide(false));
                this.dataValidate = data;
            },
                error => {
                    ConsoleUtils.log('ERROR PETICION', error);
                    this.errorCheck = error;
                    // this.showError(error);
                }
            );

        ConsoleUtils.log('llamada servicio para comprobar', null);
    }

    getFullName(member) {
        return member.name + ' ' + member.surname;
    }
}
