import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';


@Pipe({
    name: 'urlimage',
})
export class UrlImagePipe implements PipeTransform {

    // CDN_URL: string = 'https://grupoasv-meridiano-frontend.servidorbeta.com';

    // constructor(private translateService: TranslateService) { }

    transform(input: string): string {
        if (!input) {
            return '';
        }

        if (input.indexOf('http://') < 0 && input.indexOf('https://') < 0) {
            return environment.imageCDN + input;
        } else {
            return input;
        }

    }
}
