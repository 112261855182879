import { ActionReducer, Action } from '@ngrx/store';
import * as fromActions from './viewer.actions';
import { ViewerState } from './viewer.state';



const defaultState: ViewerState = {
    panelView: null,
    book: null,
    chapter: null,
    resources: null,
    currentPage: 1,
    totalResources: 0,
    totalVideos: 0,
    totalImages: 0,
    totalDocuments: 0,
    totalLinks: 0,
    isGroup: false
};

export type Action = fromActions.All;

export function ViewerReducer(state = defaultState, action: any) {
    // console.log(state, action);
    switch (action.type) {

        // case fromActions.TOGGLE_COLLAPSED:
        //     return { ...state, isCollapseAside: !state.isCollapseAside };


        case fromActions.SET_INFO:
            return { ...state,                
                book: action.payload.book,
                chapter: action.payload.chapter
            };

        case fromActions.SET_BOOK:
            return { ...state,                
                book: action.payload.book,
                chapter: null,
                resources: null,
                currentPage: 1
            };

        case fromActions.SET_CHAPTER:
            return { ...state,                
                chapter: action.payload.chapter,
                resources: action.payload.resources
            };

        case fromActions.SET_PANEL_VIEW:
            return { ...state,                
                panelView: action.payload.view
            };

        case fromActions.SET_GLOBAL_PAGE:
            return { ...state,                
                currentPage: action.payload,
            };

        case fromActions.SET_IS_GROUP:
            return { ...state,                
                isGroup: action.payload,
            };

        case fromActions.SET_TOTALS:
            return { ...state,   
                totalResources: action.payload.resources,
                totalVideos: action.payload.videos,
                totalImages: action.payload.images,
                totalDocuments: action.payload.documents,
                totalLinks: action.payload.links
            };

        case fromActions.RESET_INFO:
            return { ...defaultState };

        
        default:
            return state;
    }
}
