import { Action } from '@ngrx/store';

// PAGES
export const DO_GET_INFO_PAGES = '[Pages] Get Info Pages';
export class DoGetInfoPages implements Action {
    type = DO_GET_INFO_PAGES;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PAGES_OK = '[Pages] Get Info Pages OK';
export class DoGetInfoPagesOK implements Action {
    type = DO_GET_INFO_PAGES_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_PAGES_KO = '[Pages] Get Info Pages KO';
export class DoGetInfoPagesKO implements Action {
    type = DO_GET_INFO_PAGES_KO;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS = '[Pages] Get Info Filters';
export class DoGetInfoPagesFilters implements Action {
    type = DO_GET_INFO_FILTERS;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_OK = '[Pages] Get Info Filters OK';
export class DoGetInfoPagesFiltersOK implements Action {
    type = DO_GET_INFO_FILTERS_OK;
    constructor(public payload: any) { }
}

export const DO_GET_INFO_FILTERS_KO = '[Pages] Get Info Filters KO';
export class DoGetInfoPagesFiltersKO implements Action {
    type = DO_GET_INFO_PAGES_KO;
    constructor(public payload: any) { }
}

export const DO_SET_SELECTED_FILTERS = '[Pages] Set selected Filters';
export class DoSetSelectedFilters implements Action {
    type = DO_SET_SELECTED_FILTERS;
    constructor(public payload: any) { }
}


export type All = DoGetInfoPages | DoGetInfoPagesOK | DoGetInfoPagesKO |
    DoGetInfoPagesFilters | DoGetInfoPagesFiltersOK | DoGetInfoPagesFiltersKO 
    | DoSetSelectedFilters
    ;
