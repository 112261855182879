import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Group } from '../models/group';
import { CustomRequest } from '../helpers/custom-request';
import { BooksService } from './books.service';
import { StudentsService } from './students.service';
import { ConsoleUtils } from '../helpers/console-utils';

@Injectable({ providedIn: 'root' })
export class GroupsService {
    constructor(
        private request: CustomRequest,
        private booksService: BooksService,
        private studentsService: StudentsService
    ) { }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1}, filters: {} };
        return this.request.get(`${environment.apiUrl}/groups`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/groups`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/groups/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/groups/` + data.id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/groups`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/groups/` + id);
    }

    reset(id) {
        return this.request.post(`${environment.apiUrl}/groups/` + id + `/reset`, null);
    }

    duplicate(id) {
        return this.request.post(`${environment.apiUrl}/groups/` + id + `/duplicate`, null);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/groups/` + id + `/enabled/` + status, null);
    }

    checkUserToAdd(data: any) {
        return this.request.post(`${environment.apiUrl}/groups/` + data.group_id + `/check-user-to-add`, data);
    }

    addMember(data: any) {
        return this.request.post(`${environment.apiUrl}/groups/` + data.group_id + `/member`, data);
    }

    deleteMember(data: any) {
        return this.request.delete(`${environment.apiUrl}/groups/` + data.group_id + `/member/` + data.member_id);
    }

    changeMemberStatus(data) {
        return this.request.post(`${environment.apiUrl}/groups/` + data.group_id + `/member/` + data.member_id + `/enabled/` + data.status, null);
    }

    detailMember(groupId, memberId) {
        return this.request.get(`${environment.apiUrl}/groups/` + groupId + `/member/` + memberId);
    }

    comunicationMember(data) {
        return this.request.post(`${environment.apiUrl}/groups/` + data.group_id + `/member/` + data.member_id + `/comunication`, data);
    }

    addResource(data) {
        return this.request.post(`${environment.apiUrl}/groups/` + data.group_id + `/resource`, data);
    }

    deleteResource(data) {
        return this.request.delete(`${environment.apiUrl}/groups/` + data.group_id + `/resource/` + data.resource_id);
    }

    getMembersTypeFromApi(data, teachers) {
        if (!data.members || !data.members.length) {
            return null;
        }

        const membersType = [];
        data.members.forEach(element => {
            if (element.pivot.is_teacher === teachers) {
                membersType.push(element);
            }

            element.surname = element.surname == null ? '' : element.surname;
        });

        membersType.sort(function(a, b){
            return a.surname.toLowerCase().localeCompare(b.surname.toLowerCase())
        });

        return membersType;
    }

    getResourcesFromApi(data) {
        if (!data.resourcesViewer || !data.resourcesViewer.length) {
            return null;
        }

        return data.resourcesViewer;
    }

    transformDataFromApi(data: any) {
        const group = new Group();

        // ConsoleUtils.log('data', data);

        group.id = data.id;
        group.name = data.name;
        group.description = data.description;

        group.students = this.getMembersTypeFromApi(data, 0);
        group.teachers = this.getMembersTypeFromApi(data, 1);
        group.resources = this.getResourcesFromApi(data);

        group.book = this.booksService.transformDataFromApi(data);
        group.book.resources = group.resources;        
        group.book.chaptersUser = data.chaptersUser ? data.chaptersUser : null;;
        group.book_id = group.book_id ? group.book_id : null;

        group.created_at = data.created_at;
        group.enabled = data.enabled;
        group.code = data.code;

        group.user_id = data.user_id ? data.user_id : null;

        return group;

    }

    transformListFromApi(list: Array<any>) {
        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element));
        });

        ConsoleUtils.log('GRUPO', data);
        return data;

    }

}
