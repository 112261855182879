export class Resource {
    id: number;

    book_model_id: number;

    name: string;
    detail: string;
    
    file: string;
    file_name: string;

    private: number;
    only_teachers: number;
    destination: number;
    typology_id: number;
    position: number;
    type: number;
    social_id: string;
    url: string;
    
    all_pages: number;
    bookindex_id: number;
    page_start: number;
    page_end: number;
    pages: string;
    enabled: number;

    user_id: number;

    book_id: number;
}
