export class Group {
    id: number;

    name: string;
    description: string;
    enabled: boolean;
    code: string;

    students: any;
    teachers: any;

    resources: any;
    
    book_id: number;
    book: any;

    user_id: number;

    created_at: string;
}
