import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { FaqCategory } from '../models/faq-category';
import { CustomRequest } from '../helpers/custom-request';

@Injectable({ providedIn: 'root' })
export class FaqsCategoriesService {
    constructor(
        private request: CustomRequest
    ) { }

    get(data) {
        return this.request.get(`${environment.apiUrl}/faq-category`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/faq-category/` + id);
    }

    update(data: any) {
        return this.request.post(`${environment.apiUrl}/faq-category/` + data._id, data);
    }

    create(data: any) {
        return this.request.post(`${environment.apiUrl}/faq-category/`, data);
    }

    delete(id) {
        return this.request.delete(`${environment.apiUrl}/faq-category/` + id);
    }

    changeStatus(id, status) {
        return this.request.post(`${environment.apiUrl}/faq-category/` + id + `/enabled/` + status, null);
    }

    transformCategoriesFromApi(data: any) {
        const categories = [];

        data.forEach(element => {
            categories.push(this.transformDataFromApi(element));
        });

        return categories;
    }

    transformDataFromApi(dataCategory: any) {
        const category = new FaqCategory();

        category._id = dataCategory._id;
        category.name = dataCategory.name;
        category.image = dataCategory.image;
        category.enabled = dataCategory.enabled;
        category.code = dataCategory.code;
        category.sites = dataCategory.sites;
        category.faqs = dataCategory.faqs;

        return category;
    }
}