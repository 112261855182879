import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent  {

  @Input() items: Array<any>;

  constructor(
    private router: Router,
    public routesService: RoutesService
  ) {
    // console.log('items...');
  }

  
}
