import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { Student } from '../models/student';
import { CustomRequest } from '../helpers/custom-request';
import { BooksService } from './books.service';

@Injectable({ providedIn: 'root' })
export class StudentsService {
    constructor(
        private request: CustomRequest,
        private booksService: BooksService
    ) { }

    getFilters() {
        const data = { paginator: { perPage: 500, currentPage: 1}, filters: {} };
        return this.request.get(`${environment.apiUrl}/students`, data);
    }

    get(data) {
        return this.request.get(`${environment.apiUrl}/students`, data.payload);
    }

    detail(id) {
        return this.request.get(`${environment.apiUrl}/students/` + id);
    }
   

    transformDataFromApi(data: any, test: boolean) {
        const student = new Student();

        if (test) {
            data = this.testDetail();
        }

        student.id = data.id;
        student.name = data.name;
        student.surname = data.surname == null ? '' : data.surname;
        student.description = data.description;
        student.created_at = data.created_at;
        student.enabled = data.enabled;

        return student;

    }

    transformListFromApi(list: Array<any>) {

        list = this.testList();

        const data = [];
        list.forEach(element => {
            data.push(this.transformDataFromApi(element, false));
        });
        
        return data;

    }

    testDetail() {
        return {
            id: 49,
            name: 'Jorge Canteli Vigón',
            email: 'jorcanvi@gmail.com',
            phone: '686 39 08 75',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi rhoncus finibus sem.',
            created_at: '2020-09-01',
            enabled: 1
        };
    }

    testList() {
        return [
            {
                id: 49,
                name: 'Jorge Canteli Vigón',
                email: 'jorcanvi@gmail.com',
                phone: '686 39 08 75',
                created_at: '2020-09-01',
                enabled: 1,
                visits: 23
            },
            {
                id: 48,
                name: 'Marcos Canteli Villa',
                email: 'marcos.canteli.villa@gmail.com',
                phone: '686 29 01 15',
                created_at: '2020-09-01',
                enabled: 1,
                visits: 3
            },
            {
                id: 42,
                name: 'Inma Villa Fernández',
                email: 'pityvilla@gmail.com',
                phone: '678 39 08 77',
                created_at: '2020-09-01',
                enabled: 1,
                visits: 32
            },
            {
                id: 43,
                name: 'Martina Canteli Villa',
                email: 'martina.canteli.villa@gmail.com',
                phone: '646 39 31 72',
                created_at: '2020-09-01',
                enabled: 1,
                visits: 232
            },
            {
                id: 39,
                name: 'Enrique Ortea Varela',
                email: 'enrique@gmail.com',
                phone: '626 29 08 45',
                created_at: '2020-09-01',
                enabled: 1,
                visits: 123
            }
        ];
    }
}
