import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ConsoleUtils {

    static log(text, data) {
        if (environment.production) {
            return;
        }

        if (data) {
            console.log(text, data);
        } else {
            console.log(text);
        }
    }
}
