import { newFromJson } from '../lib/helpers/utils';

export class User {
    id: number;
    user: string;
    name: string;
    phone: string;
    avatar: string;
    center: string;
    created_at: string;
    updated_at: string;
    
    group: any;

    groups: any;

    password: string;
    token?: string;

    public static newFromJson(json: string): User | User[] {
        return newFromJson<User>(json, User);
    }
}