import { Component, OnInit, ɵConsole, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '../../services/routes.service';


import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/index';
import { AuthState } from '../../../store/auth/auth.state';

import { User } from '@app/shared/models';



@Component({
    selector: 'app-admin-info',
    templateUrl: './admin-info.component.html',
})
export class AdminInfoComponent implements OnInit, OnDestroy {
    currentUser: User;

    private suscriptionsToManage$: Subscription[] = [];

    constructor(
        public routesService: RoutesService,
        private store: Store<AppState>
    ) {
        this.initSuscriptions();
    }

    initSuscriptions() {
        this.suscriptionsToManage$.push(this.store.select(state => state.auth.user).subscribe(res => {
            this.currentUser = res;
        }));
    }

    ngOnInit(): void {

    }

    ngOnDestroy() {
        this.suscriptionsToManage$.forEach(subscription => subscription.unsubscribe());
    }

}

